import React, { useEffect } from "react";
import ApplicationUser from "../../entities/ApplicationUser";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import ProSubscription, { ProSubscriptionSourceType, ProSubscriptionStatus, ProSubscriptionTerm, ProSubscriptionTier } from "../../entities/ProSubscription";
import AppConfig from "../../entities/AppConfig";
import { AccountService } from "../../services/AccountService";
import { CurrencyHelper } from "../../utilities/CurrencyHelper";
import { Link } from "react-router-dom";
import ProInvoice from "../../entities/ProInvoice";
import { routes } from "../../utilities/routes";
import DownloadIcon from "@material-ui/icons/CloudDownload";

interface Props {
	user: ApplicationUser;
	config: AppConfig;
}

const canChangeProSubscription = (user: ApplicationUser) =>
	user.proSubscription && user.proSubscription.nextBillDate && user.proSubscription.status !== ProSubscriptionStatus.Cancelled;

const canUpgradeProSubscription = (user: ApplicationUser) => canChangeProSubscription(user) && user.proSubscription!.tier < ProSubscriptionTier.Premium;

function getPlanName(user: ApplicationUser) {
	if (user.proSubscription != null) {
		if (user.proSubscription.planName.match(/employee/i)) {
			return "Site Administrator";
		}
		if (user.proSubscription.planName.match(/basic/i)) {
			return "PRO Basic";
		}
		if (user.proSubscription.planName.match(/premium/i)) {
			if (user.proSubscription.source === ProSubscriptionSourceType.Gloo) {
				return "Gloo PRO Premium";
			}
			return "PRO Premium";
		}
		if (user.proSubscription.planName.match(/free pro/i)) {
			return "Free Contributor PRO";
		}

		return "PRO Plus";
	}

	return "Free Account";
}

export function MyProMembershipPage(props: Props) {
	const { user } = props;
	const { proSubscription } = user;
	return (
		<Grid container spacing={2} justify="center">
			<Grid item lg={4} xs={12}>
				{proSubscription ? <ProMembershipCard user={user} proSubscription={proSubscription} config={props.config} /> : <NoMembershipCard />}
			</Grid>
			<Grid item lg={8} xs={12}>
				<InvoiceHistoryCard user={user} />
			</Grid>
		</Grid>
	);
}

function InvoiceHistoryCard(props: { user: ApplicationUser }) {
	const { user } = props;
	const { currency } = user;
	const [invoices, setInvoices] = React.useState<ProInvoice[]>();

	useEffect(() => {
		const fetchInvoices = async () => {
			const invoices = await AccountService.getProInvoices();
			setInvoices(invoices ?? undefined);
		};
		fetchInvoices();
	}, []);

	const downloadInvoice = (invoiceId: number) => {
		window.open(routes.pro.resolve.downloadInvoice(invoiceId), "_blank");
	};

	if (invoices === undefined) {
		return (
			<Card>
				<CardHeader title="Invoice History" />
				<CardContent>
					<Grid container justify="center">
						<Grid item>
							<CircularProgress />
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}

	if (invoices.length === 0) {
		return (
			<Card>
				<CardHeader title="Invoice History" />
				<CardContent>
					<Typography>You have no PRO invoices yet.</Typography>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card>
			<CardHeader title="Invoice History" style={{ paddingBottom: 5 }} />
			<div style={{ overflowX: "auto" }}>
				<Table size="small">
					<TableHead>
						<TableRow>
                            <TableCell></TableCell>
							<TableCell>Invoice</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Amount</TableCell>
							<TableCell>Paid</TableCell>
							<TableCell>Balance</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invoices.map((invoice) => (
							<TableRow key={invoice.id}>
                                <TableCell>
                                    <IconButton onClick={() => downloadInvoice(invoice.id)}>
                                        <DownloadIcon />
                                    </IconButton>
                                </TableCell>
								<TableCell>{invoice.invoiceNumber}</TableCell>
								<TableCell>{new Date(invoice.invoiceDate).toLocaleDateString()}</TableCell>
								<TableCell>
									{CurrencyHelper.GetCurrencySymbol(currency)}
									{invoice.invoiceAmount.toFixed(2)}
								</TableCell>
								<TableCell>{CurrencyHelper.GetCurrencySymbol(currency) + invoice.paymentAmount.toFixed(2)}</TableCell>
								<TableCell>{CurrencyHelper.GetCurrencySymbol(currency) + invoice.balance.toFixed(2)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</Card>
	);
}

function NoMembershipCard() {
	return (
		<Card>
			<CardHeader title="No PRO Membership" />
			<CardContent>
				<Typography>
					It looks like you don't have a PRO membership right now. You can enjoy extra benefits exclusive to our subscribers by{" "}
					<a href="https://web.sermoncentral.com/subscribe/">signing up here!</a>
				</Typography>
			</CardContent>
		</Card>
	);
}

function ProMembershipCard(props: { user: ApplicationUser; proSubscription: ProSubscription; config: AppConfig }) {
	const { user, proSubscription, config } = props;
	const { currency } = user;

	const [hasPaypal, setHasPaypal] = React.useState<boolean | null>(null);

	React.useEffect(() => {
		const checkPaypal = async () => {
			const response = await AccountService.hasPayPal();
			setHasPaypal(response);
		};
		checkPaypal();
	}, [config.paypalUrl]);

	return (
		<Paper>
			<CardHeader title="My PRO" style={{ paddingBottom: 0 }} />

			<List>
				<ListItem>
					<ListItemText primary={ProSubscriptionTier[proSubscription.tier] + (proSubscription.inTrial ? " (Trial)" : "")} secondary="Benefits level" />
					{canChangeProSubscription(user) && (
						<ListItemSecondaryAction>
							<a href={`${props.config.mainSiteUrl}/pro-benefits`} style={{ textDecoration: "none" }}>
								<Button size="small" color="primary">
									View Benefits
								</Button>
							</a>
						</ListItemSecondaryAction>
					)}
				</ListItem>
				<ListItem>
					<ListItemText primary={ProSubscriptionStatus[proSubscription.status]} secondary="Membership status" />
					{canChangeProSubscription(user) && (
						<ListItemSecondaryAction>
							{hasPaypal === null && (
								<Button size="small" disabled>
									Checking...
								</Button>
							)}
							{hasPaypal === false && (
								<a href={`${props.config.paymentsSiteUrl}/cancel`} style={{ textDecoration: "none" }}>
									<Button size="small" style={{ color: "#d32f2f" }}>
										Cancel Membership
									</Button>
								</a>
							)}
							{hasPaypal === true && (
								<a target="_blank" rel="noopener noreferrer" href={`${props.config.paypalUrl}/myaccount/autopay/connect`} style={{ textDecoration: "none" }}>
									<Button size="small" color="primary">
										Manage
									</Button>
								</a>
							)}
						</ListItemSecondaryAction>
					)}
					{proSubscription.serviceStopDate && proSubscription.status === ProSubscriptionStatus.Cancelled && (
						<Typography color="secondary">Benefits end {new Date(proSubscription.serviceStopDate).toLocaleDateString()}</Typography>
					)}
				</ListItem>
				{proSubscription.status === ProSubscriptionStatus.Active && (
					<ListItem>
						<ListItemText
							primary={
								<>
									{ProSubscriptionTerm[proSubscription.term].toString()}
									{proSubscription.nextBillPrice && proSubscription.planPrice ? (
										<span>
											{proSubscription.nextBillPrice < proSubscription.planPrice ? (
												<span>
													{" "}
													(
													<strong>
														{CurrencyHelper.GetCurrencySymbol(currency)}
														{proSubscription.nextBillPrice.toString(10)}
														{proSubscription.term === 1 ? " / mth" : proSubscription.term === 12 ? " / yr" : " / 2 years"}
													</strong>{" "}
													|{" "}
													<del>
														{CurrencyHelper.GetCurrencySymbol(currency)}
														{proSubscription.planPrice.toString(10)}
														{proSubscription.term === 1 ? " / mth" : proSubscription.term === 12 ? " / yr" : " / 2 years"}
													</del>
													)
												</span>
											) : (
												<span>
													{" "}
													({CurrencyHelper.GetCurrencySymbol(currency)}
													{proSubscription.planPrice.toString(10)}
													{proSubscription.term === 1 ? " / month" : proSubscription.term === 12 ? " / year" : "/ 2 years"})
												</span>
											)}
										</span>
									) : (
										""
									)}
									{getPlanName(user) === "Free Contributor PRO" ? " (Free Contributor PRO)" : ""}
									{getPlanName(user) === "Site Administrator" ? " (Site Administrator PRO)" : ""}
								</>
							}
							secondary="Payment option"
						/>
						{canUpgradeProSubscription(user) && (
							<ListItemSecondaryAction>
								<a href={`${props.config.paymentsSiteUrl}/upgrade`} style={{ textDecoration: "none" }}>
									<Button size="small" color="primary">
										Upgrade Plan
									</Button>
								</a>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				)}
				{proSubscription.nextBillDate && proSubscription.status === ProSubscriptionStatus.Active && (
					<ListItem>
						<ListItemText primary={new Date(proSubscription.nextBillDate).toLocaleDateString()} secondary="Payment due" />
						{canChangeProSubscription(user) && (
							<ListItemSecondaryAction>
								<Link to="/account/payment-methods" style={{ textDecoration: "none" }}>
									<Button size="small" color="primary">
										Change Payment Method
									</Button>
								</Link>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				)}
			</List>
		</Paper>
	);
}
