import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface RedirectRouteProps extends RouteProps {
    redirectPath: string;
}

export const RedirectRoute: React.FunctionComponent<RedirectRouteProps> = ({ component, ...rest }) => {
    return <Route
        {...rest}
        render={(routeProps) => {
            const redirectProps = {
                to: {
                    pathname: rest.redirectPath,
                    state: { from: routeProps.location },
                },
            };

            return <Redirect {...redirectProps} />;
        }}
    />;
};