import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { VideoMedia, VideoMediaType, MediaStatus } from '../../../../entities/VideoMedia';
import { FieldValidationError, ServerResponse } from '../../../../services/ServiceHelper';
import { SaveVideoMediaRequest, VideoMediaService, SaveApprovedVideoMediaRequest, SaveRejectedVideoMediaRequest } from '../../../../services/VideoMediaService';
import DateHelper from '../../../../utilities/DateHelper';
import SuccessSnackbar from '../../../../components/SuccessSnackbar';
import ErrorSnackbar from '../../../../components/ErrorSnackbar';
import withRoot from '../../../../withRoot';
import AppConfig from '../../../../entities/AppConfig';
import VideoMediaUpload from './videoMediaUpload';
import { MediaItemStatus } from '../../../../entities/MediaItem';
import TextField from '@material-ui/core/TextField';
import { MediaService, MediaDisableRequest, MediaDeleteRequest, MediaEnableRequest, MediaCancelRequest } from '../../../../services/MediaService';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            minHeight: '70vh',
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto',
            [theme.breakpoints.up('sm')]: {
                minWidth: 800
            }
        },
        form: {
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formControl: {
            width: '100%'
        },
        formButtonsWrapper: {
            flex: '0 0 auto',
            margin: '8px 4px',
            paddingBottom: '20px',
            display: 'flex',
            alignItems: 'center'
        },
        formButton: {
            marginLeft: 'auto'
        },
        chip: {
            margin: theme.spacing() / 2,
            color: "#FFF",
            backgroundColor: theme.palette.primary.light,
            "& svg": {
                color: "rgb(255,255,255,.7)",
                "&:hover": {
                    color: theme.palette.primary.main
                }
            }
        },
        weightedTopicChip: {
            backgroundColor: theme.palette.secondary.light,
            "& svg": {
                "&:hover": {
                    color: theme.palette.secondary.main
                }
            }
        },
        buttonProgress: {
            color: "rgb(255,255,255,.7)",
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    });

interface Props extends WithStyles<typeof styles> {
    config: AppConfig;
    id?: string;
    status: MediaStatus;
    type?: string;
    onPathChange: (path: string) => void;
    onPathReplace: (path: string) => void;
}

interface State {
    loading: boolean;
    videoMedia?: VideoMedia;
    videoMediaId?: number;
    url?: string;
    dateAvailableValue?: string;
    isEnabled: boolean;
    showAddProducerDialog: boolean;
    title: string;
    summary: string;
    language: string;
    serviceStyles: string[];
    audiences: string[];
    serviceGenres: string[];
    topics: string[];
    topicInput: string;
    scriptureReferences: string[];
    scriptureReferenceInput: string;
    listPrice: number;
    hasDiscount: boolean;
    discountedPrice: number;
    showSaveConfirmationDialog: boolean;
    disableSubmit: boolean;
    errors: FieldValidationError[];
    successMessage?: string;
    errorMessage?: string;
    videoMediaType: VideoMediaType;
    status: MediaStatus;
    readonly: boolean;
    activateReadOnlyPrompt: boolean;
    reasonForUpdate: string;
    creatingPending: boolean;
    showDisable: boolean;
    disableReason: string;
    showDelete: boolean;
    deleteReason: string;
    showEnable: boolean;
    enableReason: string;
    showCancel: boolean;
}

class VideoMediaDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const addType = this.props.type ? this.getAddType(this.props.type) : VideoMediaType.Video_Illustration;

        this.state = {
            loading: true,
            videoMediaId: this.props.id ? parseInt(this.props.id, 10) : undefined,
            status: this.props.status,
            videoMediaType: addType,
            isEnabled: false,
            showAddProducerDialog: false,
            title: "",
            summary: "",
            language: "English",
            serviceStyles: [],
            audiences: [],
            serviceGenres: [],
            topics: [],
            topicInput: "",
            scriptureReferences: [],
            scriptureReferenceInput: "",
            listPrice: 19.99,
            hasDiscount: false,
            discountedPrice: 0,

            showSaveConfirmationDialog: false,
            disableSubmit: false,
            errors: [],
            dateAvailableValue: DateHelper.getDateStringFormattedForInput(new Date()),
            readonly: this.props.status === MediaStatus.Approved,
            activateReadOnlyPrompt: false,
            reasonForUpdate: "",
            creatingPending: false,
            showDisable: false,
            disableReason: "",
            showDelete: false,
            deleteReason: "",
            showEnable: false,
            enableReason: "",
            showCancel: false
        };
    }

    async componentDidMount() {
        if (this.props.type) {
            document.title = `SermonCentral Account - Add ${this.state.videoMediaType}`;
        }

        if (this.state.videoMediaId) {
            const videoMediaResponse = this.state.status !== MediaStatus.Approved ?
                await VideoMediaService.getPendingVideoMedia(this.state.videoMediaId) :
                await VideoMediaService.getVideoMedia(this.state.videoMediaId);

            if (ServerResponse.isSuccess<VideoMedia>(videoMediaResponse)) {
                let videoMedia = videoMediaResponse.data;
                this.setVideoMediaToState(videoMedia, () => this.setState({ loading: false }));
            }
            else {
                this.setState({ errorMessage: "Media not found" });
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    getAddType(type: string) {
        switch (type) {
            case "video-illustrations": return VideoMediaType.Video_Illustration;
            case "motion-backgrounds": return VideoMediaType.Motion_Background;
            case "countdown-videos": return VideoMediaType.Countdown_Video;
            default: return VideoMediaType.Video_Illustration;
        }
    }

    setVideoMediaToState = (videoMedia: VideoMedia, onCompletion?: () => void) => {
        document.title = `SermonCentral Admin - Edit ${videoMedia.type}`;
        this.setState({
            videoMedia: videoMedia,
            videoMediaId: videoMedia.id,
            title: videoMedia.title,
            videoMediaType: videoMedia.type,
            summary: videoMedia.summary,
            language: videoMedia.language,
            isEnabled: videoMedia.isEnabled,
            dateAvailableValue: videoMedia.dateAvailable ? DateHelper.getDateStringFormattedForInput(videoMedia.dateAvailable) : undefined,
            listPrice: videoMedia.listPrice || 0,
            discountedPrice: videoMedia.discountedPrice || 0,
            hasDiscount: videoMedia.discountedPrice != null,
            serviceGenres: videoMedia.genres,
            audiences: videoMedia.audiences,
            serviceStyles: videoMedia.styles,
            scriptureReferences: videoMedia.scriptureReferences,
            topics: videoMedia.topics,
            url: videoMedia.url,
            status: videoMedia.status
        }, onCompletion);
    }

    onPageClose = () => {
        const { videoMediaType } = this.state;
        if (videoMediaType === VideoMediaType.Countdown_Video) {
            this.props.onPathChange('/media/manage/countdown-videos');
        }
        if (videoMediaType === VideoMediaType.Motion_Background) {
            this.props.onPathChange('/media/manage/motion-backgrounds');
        }
        if (videoMediaType === VideoMediaType.Video_Illustration) {
            this.props.onPathChange('/media/manage/video-illustrations');
        }
    }

    onSaveRequested = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { topics, scriptureReferences, disableSubmit } = this.state;

        if (disableSubmit) {
            return;
        }
        let errors = this.validateStatus();
        if (errors.length > 0) {
            this.setState({ errors, errorMessage: `${errors.length} Field${errors.length > 1 ? "s" : ""} Invalid` });
            return;
        }

        // Topics and scripture references aren't required but should be encouraged 
        if (topics.length < 2 || scriptureReferences.length < 1) {
            this.setState({ showSaveConfirmationDialog: true });
            return;
        }

        this.onSaveVideoMedia();
    }

    onSaveVideoMedia = async () => {
        let {
            videoMediaId,
            videoMediaType,
            status,
            dateAvailableValue,
            title,
            summary,
            language,
            topics,
            scriptureReferences,
            serviceStyles,
            audiences,
            serviceGenres,
            listPrice,
            hasDiscount,
            discountedPrice,
            videoMedia,
        } = this.state;

        // Type check nullable state for typescript 
        // (required fields should be caught in validation step, so this should never actually return)
        if (!dateAvailableValue) {
            return;
        }
        this.setState({ disableSubmit: true, errors: [] });

        let request: SaveVideoMediaRequest = {
            id: videoMediaId,
            type: videoMediaType,
            dateAvailable: new Date(dateAvailableValue),
            title: title,
            summary: summary,
            language: language,
            topics: topics,
            scriptureReferences: scriptureReferences,
            serviceStyles,
            audiences,
            serviceGenres,
            listPrice,
            discountedPrice: hasDiscount ? discountedPrice : undefined,
            status: status,
            parentId: videoMedia ? videoMedia.parentId : undefined,
            adminComment: videoMedia?.adminComment,
            producerComment: videoMedia?.producerComment
        };
        let response = await VideoMediaService.saveVideoMedia(request);
        if (ServerResponse.isSuccess<VideoMedia>(response)) {
            const message = response.message;
            this.setVideoMediaToState(response.data, () => this.setState({ disableSubmit: false, successMessage: message, showSaveConfirmationDialog: false }));
            if (videoMediaId == null || this.props.status === MediaStatus.Approved) {
                this.props.onPathReplace(`/media/video-media/pending/edit/${response.data.id}`);
            }
        } else if (ServerResponse.isModelValidation(response)) {
            let hasGenericError = FieldValidationError.hasGenericError(response.errors);
            this.setState({
                disableSubmit: false,
                errorMessage: hasGenericError ? FieldValidationError.getGenericErrorSummary(response.errors) : `${response.errors.length} Field${response.errors.length > 1 ? "s" : ""} Is Invalid`,
                errors: response.errors,
                showSaveConfirmationDialog: false
            });
        } else {
            this.setState({
                disableSubmit: false,
                errorMessage: response.message,
                showSaveConfirmationDialog: false
            });
        }
    }

    styleIsSelected = (style: string) => {
        const { serviceStyles } = this.state;
        return serviceStyles.includes(style);
    }

    toggleStyle = (style: string) => {
        const { serviceStyles } = this.state;
        let isSelected = this.styleIsSelected(style);
        if (isSelected) {
            this.setState({ serviceStyles: serviceStyles.filter(s => s !== style) });
        } else {
            this.setState({ serviceStyles: [...serviceStyles, style] });
        }
    }

    audienceIsSelected = (audience: string) => {
        const { audiences } = this.state;
        return audiences.includes(audience);
    }

    toggleAudience = (audience: string) => {
        const { audiences } = this.state;
        let isSelected = this.audienceIsSelected(audience);
        if (isSelected) {
            this.setState({ audiences: audiences.filter(a => a !== audience) });
        } else {
            this.setState({ audiences: [...audiences, audience] });
        }
    }

    genreIsSelected = (genre: string) => {
        const { serviceGenres } = this.state;
        return serviceGenres.includes(genre);
    }

    toggleGenre = (genre: string) => {
        const { serviceGenres } = this.state;
        let isSelected = this.genreIsSelected(genre);
        if (isSelected) {
            this.setState({ serviceGenres: serviceGenres.filter(g => g !== genre) });
        } else {
            this.setState({ serviceGenres: [...serviceGenres, genre] });
        }
    }

    addTopic = () => {
        const { topics, topicInput } = this.state;
        let newTopics = topicInput
            .split(",")
            .map(t => t.trim())
            .filter(t => t)
            .filter(t => !topics.includes(t));
        if (newTopics.length === 0) {
            return;
        }

        this.setState({ topics: [...topics, ...newTopics], topicInput: "" });
    }

    removeTopic = (topicToRemove: string) => {
        this.setState({ topics: this.state.topics.filter(t => t !== topicToRemove) });
    }

    addScriptureReference = () => {
        const { scriptureReferences, scriptureReferenceInput } = this.state;
        let newScriptureReferences = scriptureReferenceInput
            .split(",")
            .map(t => t.trim())
            .filter(t => t)
            .filter(t => !scriptureReferences.includes(t));

        if (newScriptureReferences.length === 0) {
            return;
        }

        this.setState({ scriptureReferences: [...scriptureReferences, ...newScriptureReferences], scriptureReferenceInput: "" });
    }

    removeScriptureReference = (scriptureReferenceToRemove: string) => {
        const { scriptureReferences } = this.state;
        this.setState({ scriptureReferences: scriptureReferences.filter(sr => sr !== scriptureReferenceToRemove) });
    }

    getPriceStatus() {
        const { listPrice: listingPrice, discountedPrice, hasDiscount,  } = this.state;

        if (listingPrice === 0 && !hasDiscount) {
            return <>Only available for PRO Download</>;
        }

        if (discountedPrice >= listingPrice) {
            return <span style={{ color: "#f44336" }}>Invalid</span>;
        }

        return hasDiscount ? <><s>${listingPrice}</s> ${discountedPrice}</> : <>${listingPrice}</>;
    }

    validateStatus() {
        const {
            dateAvailableValue,
            title,
            summary,
            serviceGenres,
            listPrice,
            discountedPrice,
        } = this.state;

        let errors: FieldValidationError[] = [];

        if (!dateAvailableValue) {
            errors.push({ field: "DateAvailable", errors: ["Must set date that media will be available"] });
        }
        if (dateAvailableValue && isNaN(new Date(dateAvailableValue).getTime())) {
            errors.push({ field: "DateAvailable", errors: ["Must set date that media will be available"] });
        }
        if (title.trim().length === 0) {
            errors.push({ field: "Title", errors: ["Missing Title"] });
        }
        if (summary.trim().length === 0) {
            errors.push({ field: "Summary", errors: ["Missing Summary"] });
        }
        if (serviceGenres.length === 0) {
            errors.push({ field: "Genres", errors: ["Must select at least one genre"] });
        }
        if (listPrice > 0 && discountedPrice >= listPrice) {
            errors.push({ field: "DiscountedPrice", errors: ["Discounted price must be lower than listing price"] });
        }

        return errors;
    }

    onEnable = async () => {
        const { enableReason, videoMediaId, title } = this.state;

        if (!videoMediaId) {
            return;
        }

        this.setState({ disableSubmit: true });

        var request: MediaEnableRequest = {
            id: videoMediaId,
            title: title,
            reason: enableReason
        };

        let response = await MediaService.requestEnable(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                disableSubmit: false,
                enableReason: "",
                showEnable: false,
                successMessage: "A request has been sent to SermonCentral Admin. We will contact you as soon as possible."
            })
        } else {
            this.setState({
                disableSubmit: false,
                enableReason: "",
                showEnable: false,
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    onCancel = async () => {
        const { videoMediaId } = this.state;

        if (!videoMediaId) {
            return;
        }



        this.setState({ disableSubmit: true });

        var request: MediaCancelRequest = {
            id: videoMediaId,
        };

        let response = await MediaService.requestCancel(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                showCancel: false,
                successMessage: "These changes have been cancelled, redirecting back to Manage Media..."
            }, () => setTimeout(() => this.props.onPathChange('/media/manage/all'), 3000));
        } else {
            this.setState({
                disableSubmit: false,
                showCancel: false,
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    onDisable = async () => {
        const { disableReason, videoMediaId, title } = this.state;

        if (!videoMediaId) {
            return;
        }

        this.setState({ disableSubmit: true });

        var request: MediaDisableRequest = {
            id: videoMediaId,
            title: title,
            reason: disableReason
        };

        let response = await MediaService.requestDisable(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                disableSubmit: false,
                disableReason: "",
                showDisable: false,
                successMessage: "A request has been sent to SermonCentral Admin. We will contact you as soon as possible."
            })
        } else {
            this.setState({
                disableSubmit: false,
                disableReason: "",
                showDisable: false,
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    onDelete = async () => {
        const { deleteReason, videoMediaId, title } = this.state;

        if (!videoMediaId) {
            return;
        }

        this.setState({ disableSubmit: true });

        var request: MediaDeleteRequest = {
            id: videoMediaId,
            title: title,
            reason: deleteReason
        };

        let response = await MediaService.requestDelete(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                disableSubmit: false,
                deleteReason: "",
                showDelete: false,
                successMessage: "A request has been sent to SermonCentral Admin. We will contact you as soon as possible."
            })
        } else {
            this.setState({
                showDelete: false,
                deleteReason: "",
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    activeReadOnlyPrompt() {
        this.setState({ activateReadOnlyPrompt: true });
    }

    onConfirmEditInReadOnly = async () => {
        if (!this.state.videoMedia) {
            return;
        }
        if (this.state.reasonForUpdate.length === 0) {
            this.setState({ errors: [{ field: "ReasonForUpdate", errors: ["Please give a reason for the edit."] }] })
            return;
        }

        let {
            videoMediaId,
            videoMediaType,
            status,
            dateAvailableValue,
            title,
            summary,
            language,
            topics,
            scriptureReferences,
            serviceStyles,
            audiences,
            serviceGenres,
            listPrice,
            hasDiscount,
            discountedPrice,
            videoMedia,
            reasonForUpdate
        } = this.state;


        if (status === MediaStatus.Approved) {
            let request: SaveApprovedVideoMediaRequest = {
                id: videoMediaId,
                type: videoMediaType,
                dateAvailable: new Date(dateAvailableValue!),
                title: title,
                summary: summary,
                language: language,
                topics: topics,
                scriptureReferences: scriptureReferences,
                serviceStyles,
                audiences,
                serviceGenres,
                listPrice,
                discountedPrice: hasDiscount ? discountedPrice : undefined,
                status: status,
                producerComment: reasonForUpdate
            };
    
            let response = await VideoMediaService.createPendingItemFromApproved(request);
            if (ServerResponse.isSuccess<VideoMedia>(response)) {
                const message = response.message;
                this.setVideoMediaToState(response.data, () => this.setState({ disableSubmit: false, successMessage: message, creatingPending: false, activateReadOnlyPrompt: false }));
                this.props.onPathReplace(`/media/video-media/pending/edit/${response.data.id}`);
            } else {
                this.setState({
                    disableSubmit: false,
                    errorMessage: "There was an error trying to edit this media item, please try again.",
                    activateReadOnlyPrompt: false,
                    creatingPending: false
                });
            }
        } else {
            let request: SaveRejectedVideoMediaRequest = {
                id: videoMediaId!,
                type: videoMediaType,
                dateAvailable: new Date(dateAvailableValue!),
                title: title,
                summary: summary,
                language: language,
                topics: topics,
                scriptureReferences: scriptureReferences,
                serviceStyles,
                audiences,
                serviceGenres,
                listPrice,
                discountedPrice: hasDiscount ? discountedPrice : undefined,
                status: status,
                parentId: videoMedia.parentId!,
                producerComment: reasonForUpdate
            };

            let response = await VideoMediaService.changeRejectedToPending(request);
            if (ServerResponse.isSuccess<VideoMedia>(response)) {
                const message = response.message;
                this.setVideoMediaToState(response.data, () => this.setState({ disableSubmit: false, successMessage: message, creatingPending: false, activateReadOnlyPrompt: false }));
                this.props.onPathReplace(`/media/video-media/pending/edit/${response.data.id}`);
            } else {
                this.setState({
                    disableSubmit: false,
                    errorMessage: "There was an error trying to edit this media item, please try again.",
                    activateReadOnlyPrompt: false,
                    creatingPending: false
                });
            }
        }


    }

    render() {
        const classes = this.props.classes;
        const {
            loading,
            videoMedia,
            videoMediaType,
            videoMediaId,
            url,
            dateAvailableValue,
            isEnabled,
            title,
            summary,
            language,
            topics,
            topicInput,
            scriptureReferences,
            scriptureReferenceInput,
            listPrice: listingPrice,
            hasDiscount,
            discountedPrice,
            showSaveConfirmationDialog,
            disableSubmit,
            errors,
            status,
            activateReadOnlyPrompt,
            reasonForUpdate,
            creatingPending,
            disableReason,
            showDisable,
            deleteReason,
            showDelete,
            enableReason,
            showEnable,
            showCancel
        } = this.state;

        const readonly = status === MediaStatus.Approved || status === MediaStatus.Rejected;

        return (
            <div>
                <Grid container direction="column" justify="center" alignItems="center">
                    {
                        loading
                        &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" align="center">
                                Loading...
                            </Typography>
                        </div>
                    }
                    {
                        !loading
                        &&
                        <Paper elevation={1} className={classes.paper}>
                            <CardHeader
                                action={
                                    <IconButton onClick={this.onPageClose}>
                                        <CloseIcon />
                                    </IconButton>}
                                title={<Typography variant="h6"> {videoMediaId ? 'Edit' : 'Add'} {videoMediaType}</Typography>}
                                subheader={readonly ?
                                    <>
                                        <Typography variant="subtitle1" style={{display: 'flex'}}>
                                            { status === MediaStatus.Approved ? 
                                                "This item is currently approved, to make edits click 'Edit' button."
                                                :
                                                "This item has been rejected, to make edits click 'Edit button."
                                            }
                                            <Button style={{ marginLeft: 'auto' }} disabled={disableSubmit} onClick={(e) => { e.preventDefault(); this.activeReadOnlyPrompt(); }} color="primary" variant="contained" className={classes.formButton}>
                                                Edit
                                            </Button>
                                        </Typography>

                                    </>
                                    : videoMedia && videoMedia.adminComment && <Typography variant="subtitle1">SC Admin Comment: {videoMedia.adminComment}</Typography>}
                            />
                            <form className={classes.form} onSubmit={this.onSaveRequested}>
                                <FormControl error={FieldValidationError.isFieldInError(errors, 'DateAvailable')}>
                                    <InputLabel required shrink>Date Available</InputLabel>
                                    <Input
                                        margin="dense"
                                        type='date'
                                        inputProps={{
                                            name: 'DateAvailable',
                                            id: 'DateAvailable',
                                        }}
                                        value={dateAvailableValue || ''}
                                        disabled={disableSubmit || readonly}
                                        onChange={e => this.setState({ dateAvailableValue: e.target.value })}
                                    />
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'DateAvailable') }} />
                                </FormControl>
                                <br />
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(errors, 'Title')} disabled={disableSubmit || readonly}>
                                    <InputLabel htmlFor="Title" required>Title</InputLabel>
                                    <Input
                                        margin="dense"
                                        type='text'
                                        inputProps={{
                                            name: 'Title',
                                            id: 'Title',
                                            maxLength: 100
                                        }}
                                        value={title}
                                        onChange={e => this.setState({ title: e.target.value })}
                                    />
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Title') }} />
                                </FormControl>
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(errors, 'Summary')} disabled={disableSubmit || readonly}>
                                    <InputLabel htmlFor="Summary" required>Summary</InputLabel>
                                    <Input
                                        margin="dense"
                                        type='text'
                                        inputProps={{
                                            name: 'Summary',
                                            id: 'Summary',
                                            maxLength: 2000
                                        }}
                                        multiline
                                        rowsMax={6}
                                        value={summary}
                                        onChange={e => this.setState({ summary: e.target.value })}
                                    />
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Summary') }} />
                                </FormControl>
                                <FormControl style={{ marginBottom: 30, marginTop: 10 }}>
                                    <InputLabel htmlFor="age-simple">Language</InputLabel>
                                    <Select
                                        value={language}
                                        disabled={disableSubmit || readonly}
                                        onChange={e => this.setState({ language: e.target.value as string })}
                                    >
                                        <MenuItem value="English">English</MenuItem>
                                        <MenuItem value="Spanish">Spanish</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl} disabled={disableSubmit || readonly} error={FieldValidationError.isFieldInError(errors, 'ServiceStyles')}>
                                    <FormLabel>Service Styles</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.styleIsSelected("Contemporary")} onChange={() => this.toggleStyle("Contemporary")} />
                                            }
                                            label="Contemporary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.styleIsSelected("Traditional")} onChange={() => this.toggleStyle("Traditional")} />
                                            }
                                            label="Traditional"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.styleIsSelected("Progressive")} onChange={() => this.toggleStyle("Progressive")} />
                                            }
                                            label="Progressive"
                                        />
                                    </FormGroup>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'ServiceStyles') }} style={{ marginBottom: 10 }} />
                                </FormControl>
                                <FormControl className={classes.formControl} disabled={disableSubmit || readonly} error={FieldValidationError.isFieldInError(errors, 'Audiences')}>
                                    <FormLabel>Audiences</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.audienceIsSelected("Kids")} onChange={() => this.toggleAudience("Kids")} />
                                            }
                                            label="Kids"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.audienceIsSelected("Teens")} onChange={() => this.toggleAudience("Teens")} />
                                            }
                                            label="Teens"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.audienceIsSelected("Adults")} onChange={() => this.toggleAudience("Adults")} />
                                            }
                                            label="Adults"
                                        />
                                    </FormGroup>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Audiences') }} style={{ marginBottom: 10 }} />
                                </FormControl>
                                <FormControl className={classes.formControl} required disabled={disableSubmit || readonly} error={FieldValidationError.isFieldInError(errors, 'Genres')}>
                                    <FormLabel>Genres</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Emotional")} onChange={() => this.toggleGenre("Emotional")} />
                                            }
                                            label="Emotional"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Humorous")} onChange={() => this.toggleGenre("Humorous")} />
                                            }
                                            label="Humorous"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Powerful")} onChange={() => this.toggleGenre("Powerful")} />
                                            }
                                            label="Powerful"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Uplifting")} onChange={() => this.toggleGenre("Uplifting")} />
                                            }
                                            label="Uplifting"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Reflective")} onChange={() => this.toggleGenre("Reflective")} />
                                            }
                                            label="Reflective"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.genreIsSelected("Bible History")} onChange={() => this.toggleGenre("Bible History")} />
                                            }
                                            label="Bible History"
                                        />
                                    </FormGroup>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Genres') }} />
                                </FormControl>
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Topics')} disabled={disableSubmit || readonly}>
                                    <InputLabel htmlFor="addTopic">Add Topic</InputLabel>
                                    <Input
                                        margin="dense"
                                        id="add-topic-adornment"
                                        type='text'
                                        value={topicInput}
                                        onChange={e => this.setState({ topicInput: e.currentTarget.value })}
                                        onBlur={() => this.addTopic()}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.addTopic();
                                            }
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Add"
                                                    onClick={() => this.addTopic()}
                                                    disabled={topicInput.length === 0}
                                                >
                                                    <AddCircleOutlineIcon color={topicInput.length === 0 ? "disabled" : "primary"} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{
                                            name: 'addTopic',
                                            id: 'addTopic',
                                        }}
                                    />
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Topics') }} />
                                </FormControl>
                                <InputLabel shrink focused style={{ display: 'block', marginTop: 10 }} error={FieldValidationError.isFieldInError(this.state.errors, 'Topics')}>{topics.length === 0 && "No"} Topics</InputLabel>
                                <div style={{ paddingBottom: 25 }}>
                                    {
                                        topics.map(topic =>
                                            <Chip
                                                key={topic}
                                                label={topic}
                                                onDelete={() => this.removeTopic(topic)}
                                                className={classes.chip}
                                            />
                                        )
                                    }
                                </div>
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'ScriptureReferences')} disabled={disableSubmit || readonly}>
                                    <InputLabel htmlFor="addScriptureReference">Add Scripture Reference</InputLabel>
                                    <Input
                                        margin="dense"
                                        id="add-scripture-adornment"
                                        type='text'
                                        value={scriptureReferenceInput}
                                        onChange={e => this.setState({ scriptureReferenceInput: e.currentTarget.value })}
                                        onBlur={() => this.addScriptureReference()}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.addScriptureReference();
                                            }
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Add"
                                                    onClick={() => this.addScriptureReference()}
                                                    disabled={scriptureReferenceInput.length === 0}
                                                >
                                                    <AddCircleOutlineIcon color={scriptureReferenceInput.length === 0 ? "disabled" : "primary"} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{
                                            name: 'addScriptureReference',
                                            id: 'addScriptureReference',
                                        }}
                                    />
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ScriptureReferences') }} />
                                </FormControl>
                                <InputLabel shrink focused style={{ display: 'block', marginTop: 10 }} error={FieldValidationError.isFieldInError(this.state.errors, 'ScriptureReferences')}>{scriptureReferences.length === 0 && "No"} Scripture References</InputLabel>
                                <div style={{ paddingBottom: 25 }}>
                                    {
                                        scriptureReferences.map((scriptureReference, index) =>
                                            <Chip
                                                key={index}
                                                label={scriptureReference}
                                                onDelete={() => this.removeScriptureReference(scriptureReference)}
                                                className={classes.chip}
                                            />
                                        )
                                    }
                                </div>
                                {
                                    <>
                                        <Typography>Price Status: {this.getPriceStatus()}</Typography>
                                        <br />
                                        <Grid container>
                                            <Grid item>
                                                <FormControl error={FieldValidationError.isFieldInError(errors, 'ListingPrice')} disabled={disableSubmit || readonly}>
                                                    <InputLabel shrink>Listing Price</InputLabel>
                                                    <Input
                                                        margin="dense"
                                                        type='number'
                                                        inputProps={{
                                                            step: ".01",
                                                            min: "0"
                                                        }}
                                                        value={listingPrice}
                                                        onChange={e => this.setState({ listPrice: parseFloat(e.target.value) })}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    />
                                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'ListingPrice') }} />
                                                </FormControl>
                                            </Grid>
                                            {
                                                hasDiscount
                                                &&
                                                <Grid item>
                                                    <FormControl error={FieldValidationError.isFieldInError(errors, 'DiscountedPrice') || (hasDiscount && discountedPrice >= listingPrice)} style={{ marginLeft: 15 }} disabled={disableSubmit  || readonly}>
                                                        <InputLabel shrink>Discounted Price</InputLabel>
                                                        <Input
                                                            margin="dense"
                                                            type='number'
                                                            inputProps={{
                                                                step: ".01",
                                                                min: "0"
                                                            }}
                                                            value={discountedPrice}
                                                            onChange={e => this.setState({ discountedPrice: parseFloat(e.target.value) })}
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        />
                                                        {
                                                             hasDiscount && discountedPrice >= listingPrice
                                                                ?
                                                                <FormHelperText>Discounted price must be lower than listing price</FormHelperText>
                                                                :
                                                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'DiscountedPrice') }} />
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>
                                        <FormControl disabled={disableSubmit  || readonly}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={hasDiscount}
                                                        onChange={e => this.setState({ hasDiscount: e.target.checked })}
                                                        color="primary"
                                                    />
                                                }
                                                label="Has discount"
                                            />
                                        </FormControl>                                       
                                    </>
                                }
                                <div className={classes.formButtonsWrapper}>
                                    {
                                        videoMediaId
                                        &&
                                        <>
                                            {
                                                this.props.status === MediaStatus.Approved &&
                                                <>
                                                    {isEnabled
                                                        ?
                                                        <>
                                                            <Button disabled={disableSubmit} color="secondary" variant="contained" onClick={() => this.setState({ showDisable: true })} style={{ marginRight: 10 }}>
                                                                Disable
                                                        </Button>
                                                        </>
                                                        :
                                                        <>
                                                            <Button disabled={disableSubmit} color="secondary" variant="contained" onClick={() => this.setState({ showDelete: true })}>
                                                                Delete
                                                        </Button>
                                                            <Button disabled={disableSubmit} style={{ marginLeft: 10, marginRight: 10 }} color="primary" variant="contained" onClick={() => this.setState({ showEnable: true })}>
                                                                Enable
                                                        </Button>
                                                        </>
                                                    }
                                                    {
                                                        url
                                                        &&
                                                        (this.props.status === MediaStatus.Approved && (isEnabled || (videoMedia && videoMedia.previewVideoUrl && videoMedia.thumbnailImageUrl)))
                                                        &&
                                                        <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} >
                                                            <Button disabled={disableSubmit} variant="contained" color="primary">
                                                                View
                                                        <EyeIcon style={{ marginLeft: 5 }} />
                                                            </Button>
                                                        </a>
                                                    }
                                                </>
                                            }
                                            {
                                                this.props.status !== MediaStatus.Approved
                                                &&
                                                <Button disabled={disableSubmit} color="secondary" variant="contained" onClick={() => this.setState({ showCancel: true })} style={{ marginRight: 10 }}>
                                                    Cancel Changes
                                                </Button>
                                            }
                                        </>
                                    }
                                    {readonly ?
                                        <Button disabled={disableSubmit} onClick={(e) => { e.preventDefault(); this.activeReadOnlyPrompt(); }} color="primary" variant="contained" className={classes.formButton}>
                                            Edit
                                        </Button>
                                        :
                                        <Button disabled={disableSubmit} type="submit" color="primary" variant="contained" className={classes.formButton}>
                                            {videoMediaId ? 'Save Changes' : 'Submit'}
                                        </Button>
                                    }

                                </div>
                            </form>

                        </Paper>
                    }
                </Grid>
                {
                    videoMedia
                    &&
                    <VideoMediaUpload
                        videoMedia={videoMedia}
                        disableSubmit={disableSubmit}
                        onChangeDisableSubmit={submitDisabled => this.setState({ disableSubmit: submitDisabled })}
                        onUpdate={this.setVideoMediaToState}
                        onSuccess={successMessage => this.setState({ successMessage })}
                        onError={errorMessage => this.setState({ errorMessage })}
                        status={status}
                        activeReadOnlyPrompt={() => this.activeReadOnlyPrompt()}
                    />
                }
                <Dialog
                    open={showSaveConfirmationDialog}
                    onClose={() => this.setState({ showSaveConfirmationDialog: false })}
                >
                    <DialogTitle>Are You Sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1'>
                            Are you sure you wish to save without metadata being filled out?
                        </DialogContentText>
                        <br />
                        {
                            topics.length < 2
                            &&
                            <DialogContentText variant='subtitle2'>
                                - Content performs better with at least two relevant topics
                            </DialogContentText>
                        }
                        {
                            scriptureReferences.length < 1
                            &&
                            <DialogContentText variant='subtitle2'>
                                - Content performs better with at least one relevant scripture reference
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ showSaveConfirmationDialog: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={this.onSaveVideoMedia} color="primary">
                            Confirm Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <ErrorSnackbar
                    message={this.state.errorMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ errorMessage: undefined })}
                />
                <SuccessSnackbar
                    message={this.state.successMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ successMessage: undefined })}
                />
                <Dialog
                    open={activateReadOnlyPrompt}
                    onClose={() => this.setState({ reasonForUpdate: "", activateReadOnlyPrompt: false })}
                >
                    <DialogTitle>Edit {videoMediaType}</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1'>
                            Are you sure you wish to edit this media item?
                        </DialogContentText>
                        <DialogContentText variant='subtitle2'>
                            - A new 'pending' copy will be created, and will need to be approved by a SermonCentral administrator
                        </DialogContentText>
                        <DialogContentText variant='subtitle2'>
                            - The original version will not be changed until the 'pending' copy is approved.
                        </DialogContentText>
                        <DialogContentText variant='subtitle2'>
                            - If this item is on SermonCentral, it will not be updated until the approval process is completed.
                        </DialogContentText>
                        <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(errors, 'ReasonForUpdate')} disabled={creatingPending}>
                            <InputLabel htmlFor="Reason" required>Reason For Update</InputLabel>
                            <Input
                                margin="dense"
                                type='text'
                                inputProps={{
                                    name: 'Reason',
                                    id: 'Reason',
                                    maxLength: 2000
                                }}
                                multiline
                                rowsMax={6}
                                value={reasonForUpdate}
                                onChange={e => this.setState({ reasonForUpdate: e.target.value })}
                            />
                            <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'ReasonForUpdate') }} />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ reasonForUpdate: "", activateReadOnlyPrompt: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={this.onConfirmEditInReadOnly} color="primary">
                            {creatingPending ? "Creating Pending Item" : "Confirm Edit"}
                        </Button>
                        {creatingPending && <CircularProgress />}

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showDisable}
                    onClose={() => this.setState({ showDisable: false })}
                    aria-labelledby="disable-dialog-title"
                    aria-describedby="disable-dialog-description"
                >
                    <DialogTitle id="disable-dialog-title">Disable Media</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="disable-dialog-description">
                            Are you sure you wish to request this to be disabled?
                        </DialogContentText>
                        <br />
                        <DialogContentText variant='subtitle2'>
                            Please fill in a comment to explain why this item should be disabled.
                        </DialogContentText>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            fullWidth
                            rows="3"
                            required
                            defaultValue="Enter a reason..."
                            value={disableReason}
                            margin="normal"
                            variant="outlined"
                            onChange={e => this.setState({ disableReason: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ showDisable: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={async () => await this.onDisable()} color="secondary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showDelete}
                    onClose={() => this.setState({ showDelete: false })}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Delete Media</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="disable-dialog-description">
                            Are you sure you wish to request this to be deleted?
                        </DialogContentText>
                        <br />
                        <DialogContentText variant='subtitle2'>
                            Please fill in a comment to explain why this item should be deleted.
                        </DialogContentText>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            fullWidth
                            rows="3"
                            required
                            defaultValue="Enter a reason..."
                            value={deleteReason}
                            margin="normal"
                            variant="outlined"
                            onChange={e => this.setState({ deleteReason: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ showDelete: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={async () => await this.onDelete()} color="secondary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showEnable}
                    onClose={() => this.setState({ showEnable: false })}
                    aria-labelledby="enable-dialog-title"
                    aria-describedby="enable-dialog-description"
                >
                    <DialogTitle id="enable-dialog-title">Enable Media</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="enable-dialog-description">
                            Are you sure you wish to request this to be enabled?
                        </DialogContentText>
                        <br />
                        <DialogContentText variant='subtitle2'>
                            Please fill in a comment to explain why this item should be enabled.
                        </DialogContentText>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            fullWidth
                            rows="3"
                            required
                            defaultValue="Enter a reason..."
                            value={enableReason}
                            margin="normal"
                            variant="outlined"
                            onChange={e => this.setState({ enableReason: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ showEnable: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={async () => await this.onEnable()} color="secondary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showCancel}
                    onClose={() => this.setState({ showCancel: false })}
                    aria-labelledby="cancel-dialog-title"
                    aria-describedby="cancel-dialog-description"
                >
                    <DialogTitle id="cancel-dialog-title">Cancel changes</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="enable-dialog-description">
                            Are you sure you wish to cancel these changes?
                        </DialogContentText>
                        <br />
                        {videoMedia?.parentId ?
                            <>
                                <DialogContentText variant='subtitle2'>
                                    By cancelling, you will be removing all changes requested. This item will no longer show for SermonCentral administrators to approve.
                                </DialogContentText>
                                <DialogContentText variant='subtitle2'>
                                    This pending or rejected item will be removed. This cannot be undone. Your original approved media item will not be affected.
                                </DialogContentText>
                            </> :
                            <>
                                <DialogContentText variant='subtitle2'>
                                    By cancelling, you will be removing this pending item. This item will no longer show for SermonCentral administrators to approve nor be available for you to edit or submit at a later date.
                                </DialogContentText>
                                <DialogContentText variant='subtitle2'>
                                    This pending item will be deleted. This cannot be undone.
                                </DialogContentText>
                            </>

                        }

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disableSubmit} onClick={() => this.setState({ showCancel: false })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={disableSubmit} onClick={async () => await this.onCancel()} color="secondary">
                            Remove Changes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(VideoMediaDetail));