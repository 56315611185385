import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ChatIcon from "@material-ui/icons/Chat";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HelpIcon from "@material-ui/icons/Help";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import MenuIcon from "@material-ui/icons/Menu";
import MovieIcon from "@material-ui/icons/Movie";
import NotesIcon from "@material-ui/icons/Notes";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import AppConfig from "../entities/AppConfig";
import ApplicationUser from "../entities/ApplicationUser";
import { AdminService } from "../services/AdminService";
import { AuthenticationService } from "../services/AuthenticationService";
import withRoot from "../withRoot";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StarIcon from "@material-ui/icons/Star";
import { routes } from "../utilities/routes";
import CartIcon from "@material-ui/icons/ShoppingCart";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			position: "absolute",
			left: 0,
			right: 0,
			bottom: 0,
			top: 0,
			zIndex: 1,
			overflow: "hidden",
			flexGrow: 1,
			display: "flex",
		},
		grow: {
			flexGrow: 1,
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		drawerPaper: {
			position: "relative",
			width: 250,
		},
		avatar: {
			backgroundColor: theme.palette.primary.light,
		},
		link: {
			textDecoration: "none",
		},
		listItem: {
			paddingRight: 0,
		},
		listItemIcon: {
			marginRight: 0,
		},
		nested: {
			paddingLeft: theme.spacing() * 3,
			paddingRight: 0,
		},
		navIconHide: {
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
		content: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing() * 3,
			minWidth: 0, // So the Typography noWrap works
			overflowY: "auto",
		},
		toolbar: theme.mixins.toolbar,
		listItemNested: {
			paddingLeft: 40,
		},
	});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
	user: ApplicationUser | null;
	config: AppConfig;
	onSignIn: (user: ApplicationUser) => void;
	onSignOut: () => void;
	children?: React.ReactNode;
}

type State = {
	anchorEl: HTMLElement | null;
	mobileDrawerOpen: boolean;
	myAccountOpen: boolean;
	myProMembershipOpen: boolean;
	myMediaOpen: boolean;
	myContributionsOpen: boolean;
	myProfileOpen: boolean;
	myPreferencesOpen: boolean;
	myMediaProducerDashboardOpen: boolean;
};

class Layout extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			anchorEl: null,
			mobileDrawerOpen: false,
			myAccountOpen: this.props.user ? this.props.user.roles.filter((r) => r === "Admin").length === 0 : false,
			myProMembershipOpen: false,
			myMediaOpen: false,
			myContributionsOpen: false,
			myProfileOpen: false,
			myPreferencesOpen: false,
			myMediaProducerDashboardOpen: this.props.user ? this.props.user.roles.filter((r) => r === "MediaProducer").length > 0 : false,
		};
	}

	async handleStopImpersonationClick(event: React.MouseEvent<HTMLElement>) {
		event.preventDefault();
		const user = await AdminService.stopImpersonation();
		if (user) {
			this.setState({ mobileDrawerOpen: false });
			this.props.onSignIn(user);
			this.props.history.push("/admin/impersonate");
		} else {
			window.location.reload();
		}
	}

	async handleSignOutClick() {
		if (this.props.user && this.props.user.inAdminImpersonationMode) {
			const user = await AdminService.stopImpersonation();
			if (user) {
				this.setState({ mobileDrawerOpen: false });
				this.props.onSignIn(user);
				this.props.history.push("/admin/impersonate");
			} else {
				window.location.reload();
			}
		} else {
			const windowLocation = await AuthenticationService.signOut();

			this.setState({ anchorEl: null });
			this.props.onSignOut();
			if (windowLocation) {
				window.location.replace(windowLocation);
			}
		}
	}

	navigateTo(clickEvent: React.MouseEvent, href: string) {
		this.setState({ mobileDrawerOpen: false });
		clickEvent.preventDefault();
		this.props.history.push(href);
	}

	renderNav() {
		const classes = this.props.classes;

		if (this.props.user) {
			return (
				<React.Fragment>
					<List component="nav" dense>
						{this.props.user.isAdmin && (
							<React.Fragment>
								{!this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.impersonate}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<SupervisorAccountIcon />
											</ListItemIcon>
											<ListItemText primary="Impersonate User" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.notes}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<NotesIcon />
											</ListItemIcon>
											<ListItemText primary="Admin Notes" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.freeProAccess}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<CardGiftcardIcon />
											</ListItemIcon>
											<ListItemText primary="Free PRO Access" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<ListItem button onClick={(e) => this.handleStopImpersonationClick(e)}>
										<ListItemIcon className={classes.listItemIcon}>
											<BlockIcon />
										</ListItemIcon>
										<ListItemText primary="Stop Impersonation" />
									</ListItem>
								)}
								<Divider />
							</React.Fragment>
						)}
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.home}>
							<ListItem button>
								<ListItemIcon className={classes.listItemIcon}>
									<DashboardIcon />
								</ListItemIcon>
								<ListItemText primary="Account Dashboard" />
							</ListItem>
						</Link>
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.pro.myMembership}>
							<ListItem button>
								<ListItemIcon className={classes.listItemIcon}>
									<StarIcon />
								</ListItemIcon>
								<ListItemText primary="My PRO Membership" />
							</ListItem>
						</Link>

						<ListItem button onClick={() => this.setState({ myAccountOpen: !this.state.myAccountOpen })}>
							<ListItemIcon className={classes.listItemIcon}>
								<AccountBoxIcon />
							</ListItemIcon>
							<ListItemText primary="My Account" />
							{this.state.myAccountOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myAccountOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.changeEmail}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Change Email" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.changePassword}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Change Password" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.changePhone}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Change Phone" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.paymentMethods}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Payment Methods" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
                        <Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.media.purchases}>
							<ListItem button>
								<ListItemIcon className={classes.listItemIcon}>
									<CartIcon />
								</ListItemIcon>
								<ListItemText primary="My Purchases" />
							</ListItem>
						</Link>
						<ListItem button onClick={() => this.setState({ myMediaOpen: !this.state.myMediaOpen })}>
							<ListItemIcon className={classes.listItemIcon}>
								<MovieIcon />
							</ListItemIcon>
							<ListItemText primary="My Media" />
							{this.state.myMediaOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myMediaOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.media.downloads}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Download History" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.media.activations}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Kit Activation History" />
									</ListItem>
								</Link>
							</List>
						</Collapse>

						<ListItem
							button
							onClick={() =>
								this.setState({
									myContributionsOpen: !this.state.myContributionsOpen,
								})
							}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<LanguageIcon />
							</ListItemIcon>
							<ListItemText primary="My Contributions" />
							{this.state.myContributionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myContributionsOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								{this.props.user.roles.some((r) => r === "Contributor") && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.contributions.dashboard}>
										<ListItem button className={classes.nested}>
											<ListItemText inset primary="Contribution Dashboard" />
										</ListItem>
									</Link>
								)}
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.contributions.sermons}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Sermons" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.contributions.sermonIllustrations}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Sermon Illustrations" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.contributions.sermonSeries}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Sermon Series" />
									</ListItem>
								</Link>
							</List>
						</Collapse>

						<ListItem button onClick={() => this.setState({ myProfileOpen: !this.state.myProfileOpen })}>
							<ListItemIcon className={classes.listItemIcon}>
								<FaceIcon />
							</ListItemIcon>
							<ListItemText primary="My Profile" />
							{this.state.myProfileOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myProfileOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.profile.myChurch}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="About Me" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.profile.profileImage}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Profile Image" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.profile.myChurch}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="My Church" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.profile.myStory}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="My Story" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.profile.sharedLinks}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Shared Links" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
						<ListItem
							button
							onClick={() =>
								this.setState({
									myPreferencesOpen: !this.state.myPreferencesOpen,
								})
							}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="My Preferences" />
							{this.state.myPreferencesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myPreferencesOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.preferences.notifications}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Notifications" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.preferences.bibleTranslation}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Bible Translation" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.preferences.newsletterSubscriptions}>
									<ListItem button className={classes.nested}>
										<ListItemText inset primary="Newsletter Subscriptions" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
					</List>
					<Divider />
					<List component="nav" dense>
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.discussion.notifications}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<NotificationsIcon />
								</ListItemIcon>
								<ListItemText primary="Discussion Notifications" />
							</ListItem>
						</Link>
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.sermonFolders.base}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<FavoriteIcon />
								</ListItemIcon>
								<ListItemText primary="SermonFolders" />
							</ListItem>
						</Link>
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.messages.private}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<ChatIcon />
								</ListItemIcon>
								<ListItemText primary="Private Messages" />
							</ListItem>
						</Link>
						<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.viewingHistory}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<HistoryIcon />
								</ListItemIcon>
								<ListItemText primary="Viewing History" />
							</ListItem>
						</Link>
					</List>
					<Divider />
					<List component="nav" dense>
						<a
							onClick={() => this.setState({ mobileDrawerOpen: false })}
							className={classes.link}
							href={`${this.props.config.mainSiteUrl}/content/learning-library`}
						>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<HelpIcon />
								</ListItemIcon>
								<ListItemText primary="Help & Support" />
							</ListItem>
						</a>
						<a onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} href={`${this.props.config.mainSiteUrl}`}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText primary="Return to SermonCentral" />
							</ListItem>
						</a>
					</List>
				</React.Fragment>
			);
		} else {
			return null;
		}
	}

	renderMediaProducerNav() {
		const classes = this.props.classes;

		if (this.props.user) {
			return (
				<React.Fragment>
					<List component="nav" dense>
						{this.props.user.isAdmin && (
							<React.Fragment>
								{!this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.impersonate}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<SupervisorAccountIcon />
											</ListItemIcon>
											<ListItemText primary="Impersonate User" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.notes}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<NotesIcon />
											</ListItemIcon>
											<ListItemText primary="Admin Notes" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.admin.freeProAccess}>
										<ListItem button>
											<ListItemIcon className={classes.listItemIcon}>
												<CardGiftcardIcon />
											</ListItemIcon>
											<ListItemText primary="Free PRO Access" />
										</ListItem>
									</Link>
								)}
								{this.props.user.inAdminImpersonationMode && (
									<ListItem button onClick={(e) => this.handleStopImpersonationClick(e)}>
										<ListItemIcon className={classes.listItemIcon}>
											<BlockIcon />
										</ListItemIcon>
										<ListItemText primary="Stop Impersonation" />
									</ListItem>
								)}
								<Divider />
							</React.Fragment>
						)}

						<ListItem
							button
							onClick={() =>
								this.setState({
									myMediaProducerDashboardOpen: !this.state.myMediaProducerDashboardOpen,
								})
							}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<AccountBoxIcon />
							</ListItemIcon>
							<ListItemText primary="My Account" />
							{this.state.myMediaProducerDashboardOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myMediaProducerDashboardOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.changeEmail}>
									<ListItem button className={classes.nested}>
										<ListItemText className={classes.listItemNested} primary="Change Email" />
									</ListItem>
								</Link>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.account.changePassword}>
									<ListItem button className={classes.nested}>
										<ListItemText className={classes.listItemNested} primary="Change Password" />
									</ListItem>
								</Link>
							</List>
						</Collapse>
						<ListItem button onClick={() => this.setState({ myMediaOpen: !this.state.myMediaOpen })}>
							<ListItemIcon className={classes.listItemIcon}>
								<MovieIcon />
							</ListItemIcon>
							<ListItemText primary="My Media" />
							{this.state.myMediaOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={this.state.myMediaOpen} timeout="auto" unmountOnExit>
							<List disablePadding dense>
								<Link onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} to={routes.media.resolve.manage("all")}>
									<ListItem button className={classes.nested}>
										<ListItemText className={classes.listItemNested} primary="All Media" />
									</ListItem>
								</Link>
								{this.props.user.roles.filter((r) => r === "HasVideoIllustrationAccess").length > 0 && (
									<Link
										onClick={() => this.setState({ mobileDrawerOpen: false })}
										className={classes.link}
										to={routes.media.resolve.manage("video-illustrations")}
									>
										<ListItem button className={classes.nested}>
											<ListItemText className={classes.listItemNested} primary="Video Illustrations" />
											<ListItemSecondaryAction>
												<IconButton onClick={(e) => this.navigateTo(e, routes.media.resolve.pendingAdd("video-illustrations"))}>
													<AddIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									</Link>
								)}
								{this.props.user.roles.filter((r) => r === "HasMotionBackgroundAccess").length > 0 && (
									<Link
										onClick={() => this.setState({ mobileDrawerOpen: false })}
										className={classes.link}
										to={routes.media.resolve.manage("motion-backgrounds")}
									>
										<ListItem button className={classes.nested}>
											<ListItemText className={classes.listItemNested} primary="Motion Backgrounds" />
											<ListItemSecondaryAction>
												<IconButton onClick={(e) => this.navigateTo(e, routes.media.resolve.pendingAdd("motion-backgrounds"))}>
													<AddIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									</Link>
								)}
								{this.props.user.roles.filter((r) => r === "HasCountdownVideoAccess").length > 0 && (
									<Link
										onClick={() => this.setState({ mobileDrawerOpen: false })}
										className={classes.link}
										to={routes.media.resolve.manage("countdown-videos")}
									>
										<ListItem button className={classes.nested}>
											<ListItemText className={classes.listItemNested} primary="Countdown Videos" />
											<ListItemSecondaryAction>
												<IconButton onClick={(e) => this.navigateTo(e, routes.media.resolve.pendingAdd("countdown-videos"))}>
													<AddIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									</Link>
								)}
							</List>
						</Collapse>
					</List>
					<Divider />
					<List component="nav" dense>
						<a
							onClick={() => this.setState({ mobileDrawerOpen: false })}
							className={classes.link}
							href={`${this.props.config.mainSiteUrl}/content/learning-library`}
						>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<HelpIcon />
								</ListItemIcon>
								<ListItemText primary="Help & Support" />
							</ListItem>
						</a>
						<a onClick={() => this.setState({ mobileDrawerOpen: false })} className={classes.link} href={`${this.props.config.mainSiteUrl}`}>
							<ListItem button className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText primary="Return to SermonCentral" />
							</ListItem>
						</a>
					</List>
				</React.Fragment>
			);
		} else {
			return null;
		}
	}

	render() {
		const classes = this.props.classes;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		if (this.props.user) {
			return (
				<div className={classes.root}>
					<AppBar position="absolute" className={classes.appBar}>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="Open drawer"
								onClick={() =>
									this.setState({
										mobileDrawerOpen: !this.state.mobileDrawerOpen,
									})
								}
								className={classes.navIconHide}
							>
								<MenuIcon />
							</IconButton>
							<Hidden mdUp>
								<Typography variant="h6" color="inherit" className={classes.grow}>
									SC Account
								</Typography>
							</Hidden>
							<Hidden smDown>
								{!this.props.user.inAdminImpersonationMode ? (
									<Typography variant="h6" color="inherit" className={classes.grow}>
										SermonCentral Account
									</Typography>
								) : (
									<Typography variant="h6" color="inherit" className={classes.grow}>
										SermonCentral Account
										<Typography variant="body2" color="inherit">
											Impersonating User #{this.props.user.id} ({this.props.user.firstName} {this.props.user.lastName} - {this.props.user.email})
										</Typography>
									</Typography>
								)}
							</Hidden>
							<div>
								{this.props.user.profileImageUrl ? (
									<Avatar onClick={(e) => this.setState({ anchorEl: e.currentTarget })} className={classes.avatar} src={this.props.user.profileImageUrl} />
								) : (
									<Avatar onClick={(e) => this.setState({ anchorEl: e.currentTarget })} className={classes.avatar}>
										{this.props.user.firstName.charAt(0)}
										{this.props.user.lastName.charAt(0)}
									</Avatar>
								)}
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={open}
									onClose={() => this.setState({ anchorEl: null })}
								>
									<MenuItem onClick={() => this.handleSignOutClick()}>Sign Out</MenuItem>
								</Menu>
							</div>
						</Toolbar>
					</AppBar>
					<Hidden mdUp>
						<Drawer
							variant="temporary"
							anchor={"left"}
							open={this.state.mobileDrawerOpen}
							onClose={() =>
								this.setState({
									mobileDrawerOpen: !this.state.mobileDrawerOpen,
								})
							}
							classes={{
								paper: classes.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
						>
							{this.props.user.roles.filter((r) => r === "MediaProducer").length > 0 ? this.renderMediaProducerNav() : this.renderNav()}
						</Drawer>
					</Hidden>
					<Hidden smDown>
						<Drawer
							variant="permanent"
							open
							classes={{
								paper: classes.drawerPaper,
							}}
						>
							<div className={classes.toolbar} />
							{this.props.user.roles.filter((r) => r === "MediaProducer").length > 0 ? this.renderMediaProducerNav() : this.renderNav()}
						</Drawer>
					</Hidden>

					<main id="main" className={classes.content}>
						<div className={classes.toolbar} />
						{this.props.children}
					</main>
				</div>
			);
		} else {
			return this.props.children;
		}
	}
}

export default withRouter(withRoot(withStyles(styles)(Layout)));
