type MediaRouteTypes = "all" | "video-illustrations" | "motion-backgrounds" | "countdown-videos";

export const routes = {
    auth: {
        signIn: "/auth/sign-in",
        register: "/auth/register",
        forgotPassword: "/auth/forgot-password",
        forgotPasswordSuccess: "/auth/forgot-password/success",
        resetPassword: "/auth/reset-password/:email/:code",
        resolve: {
            resetPassword: (email: string, code: string) => `/auth/reset-password/${email}/${code}`,
        }
    },
    admin: {
        impersonate: "/admin/impersonate",
        notes: "/admin/notes",
        freeProAccess: "/admin/free-pro-access",
    },
    pro: {
        myMembership: "/pro/my-membership",
        downloadInvoice: "/api/Account/DownloadProInvoice/:id",
        resolve: {
            downloadInvoice: (id: number) => `/api/Account/DownloadProInvoice/${id}`,
        }
    },
    account: {
        changeEmail: "/account/change-email",
        changePassword: "/account/change-password",
        changePhone: "/account/change-phone",
        paymentMethods: "/account/payment-methods",
        billingStatements: "/account/billing-statements",
    },
    media: {
        downloads: "/media/downloads",
        purchases: "/media/purchases",
        activations: "/media/activations",
        manage: "/media/manage/:type",
        pendingAdd: "/media/video-media/pending/add/:type",
        pendingEdit: "/media/video-media/pending/edit/:id",
        approvedEdit: "/media/video-media/approved/edit/:id",
        downloadInvoice: "/api/Media/DownloadPurchaseInvoice/:id",
        resolve: {
            manage: (type: MediaRouteTypes) => `/media/manage/${type}`,
            pendingAdd: (type: MediaRouteTypes) => `/media/video-media/pending/add/${type}`,
            pendingEdit: (id: number) => `/media/video-media/pending/edit/${id}`,
            approvedEdit: (id: number) => `/media/video-media/approved/edit/${id}`,
            downloadInvoice: (id: number) => `/api/Media/DownloadPurchaseInvoice/${id}`,
        }
    },
    contributions: {
        dashboard: "/contributions",
        sermonIllustrations: "/contributions/sermon-illustrations",
        addSermonIllustration: "/contributions/sermon-illustrations/add",
        editSermonIllustration: "/contributions/sermon-illustrations/edit/:id",
        sermons: "/contributions/sermons",
        addSermon: "/contributions/sermons/add",
        editSermon: "/contributions/sermons/edit/:id",
        sermonSeries: "/contributions/sermon-series",
        addSeries: "/contributions/sermon-series/add",
        editSeries: "/contributions/sermon-series/edit/:id",
        resolve: {
            editSermonIllustration: (id: number) => `/contributions/sermon-illustrations/edit/${id}`,
            editSermon: (id: number) => `/contributions/sermons/edit/${id}`,
            editSeries: (id: number) => `/contributions/sermon-series/edit/${id}`,
        }
    },
    discussion: {
        notifications: "/discussion-notifications",
    },
    messages: {
        private: "/private-messages",
    },
    sermonFolders: {
        base: "/sermon-folders",
        id: "/sermon-folders/:id",
        resolve: {
            id: (id: number) => `/sermon-folders/${id}`,
        }
    },
    viewingHistory: "/viewing-history",
    preferences: {
        bibleTranslation: "/preferences/bible-translation",
        newsletterSubscriptions: "/preferences/newsletter-subscriptions",
        notifications: "/preferences/notifications",
    },
    profile: {
        aboutMe: "/profile/about-me",
        myChurch: "/profile/my-church",
        myStory: "/profile/my-story",
        sharedLinks: "/profile/shared-links",
        profileImage: "/profile/profile-image",
    },
    home: "/",
};