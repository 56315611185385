import { library } from "@fortawesome/fontawesome-svg-core";
import { faCcAmex, faCcDiscover, faCcMastercard, faCcPaypal, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./app.css";
import AnalyticsPageTracker from "./components/AnalyticsPageTracker";
import { PrivateRoute } from "./components/PrivateRoute";
import { RedirectRoute } from "./components/RedirectRoute";
import AppConfig, { AppConfigEnvironment } from "./entities/AppConfig";
import ApplicationUser from "./entities/ApplicationUser";
import Layout from "./layout/layout";
import ChangeEmail from "./pages/account/changeEmail";
import ChangePassword from "./pages/account/changePassword";
import ChangePhone from "./pages/account/changePhone";
import AccountDashboard from "./pages/account/home";
import MediaDashboard from "./pages/account/mediaDashboard";
import PaymentMethods from "./pages/account/paymentMethods";
import FreeProAccess from "./pages/admin/freeProAccess";
import Impersonate from "./pages/admin/impersonate";
import Notes from "./pages/admin/notes";
import ForgotPassword from "./pages/authentication/forgotPassword";
import ForgotPasswordSuccess from "./pages/authentication/forgotPasswordSuccess";
import Register from "./pages/authentication/register";
import ResetPassword from "./pages/authentication/resetPassword";
import SignIn from "./pages/authentication/signIn";
import EditSermon from "./pages/contributions/editSermon";
import EditSermonIllustration from "./pages/contributions/editSermonIllustration";
import AddEditSeries from "./pages/contributions/editSermonSeries";
import ContributorDashboard from "./pages/contributions/home";
import SermonIllustrations from "./pages/contributions/sermonIllustrations";
import SermonSeries from "./pages/contributions/sermonSeries";
import Sermons from "./pages/contributions/sermons";
import Activations from "./pages/media/activations";
import Downloads from "./pages/media/downloads";
import ManageMedia from "./pages/media/media-producer/manageMedia";
import ApprovedVideoMedia from "./pages/media/media-producer/video-media/approvedVideoMedia";
import PendingVideoMedia from "./pages/media/media-producer/video-media/pendingVideoMedia";
import Purchases from "./pages/media/purchases";
import DiscussionNotifications from "./pages/misc/discussionNotifications";
import PrivateMessages from "./pages/misc/privateMessages";
import SermonFolders from "./pages/misc/sermonFolders";
import ViewingHistory from "./pages/misc/viewingHistory";
import NewsletterSubscriptions from "./pages/preferences/newsletterSubscriptions";
import Notifications from "./pages/preferences/notificationsPreferences";
import BibleTranslation from "./pages/preferences/preferredBibleTranslation";
import AboutMe from "./pages/profile/aboutMe";
import MyChurch from "./pages/profile/myChurch";
import MyStory from "./pages/profile/myStory";
import ProfileImage from "./pages/profile/profileImage";
import SharedLinks from "./pages/profile/sharedLinks";
import { AppConfigService } from "./services/AppConfigService";
import { AuthenticationService } from "./services/AuthenticationService";
import { routes } from "./utilities/routes";
import { MyProMembershipPage } from "./pages/pro/MyProMembershipPage";

library.add(faCcAmex);
library.add(faCcDiscover);
library.add(faCcMastercard);
library.add(faCcVisa);
library.add(faCcPaypal);

interface AppProps {
	baseUrl: string;
}

interface AppState {
	loading: boolean;
	user: ApplicationUser | null;
	config: AppConfig | null;
}

class App extends React.Component<AppProps, AppState> {
	constructor(props: AppProps) {
		super(props);
		this.state = {
			loading: true,
			user: null,
			config: {
				environment: AppConfigEnvironment.Production,
				googleAnalytics: {
					isEnabled: true,
					trackingId: "UA-75701108-7",
				},
				paypalUrl: "https://sermoncentral.com",
				mainSiteUrl: "https://sermoncentral.com",
				paymentsSiteUrl: "https://payments.sermoncentral.com",
				makerSiteUrl: "https://maker.sermoncentral.com",
				sparkSiteUrl: "https://research.sermoncentral.com",
				accountSiteUrl: "https://account.sermoncentral.com",
				glooSubscriptionsUrl: "https://app.gloo.us/settings/subscriptions",
				glooUserProfileUrl: "https://app.gloo.us/settings/settings/profile/info",
			},
		};
	}

	componentDidMount() {
		AppConfigService.getAppConfig().then((config) => {
			if (config) {
				this.setState({ config: config });
			}
		});

		AuthenticationService.getAuthenticatedUser()
			.then((user) =>
				this.setState({
					user: user,
					loading: false,
				})
			)
			.catch((error) =>
				this.setState({
					user: null,
					loading: false,
				})
			);
	}

	handleUpdateUser(user: ApplicationUser | null) {
		this.setState({
			user: user,
		});
	}

	render() {
		if (this.state.loading) {
			return <img alt="" className="sc-content-spinner" src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif" />;
		} else {
			return (
				<BrowserRouter basename={this.props.baseUrl}>
					<AnalyticsPageTracker config={this.state.config!}>
						<Layout
							config={this.state.config!}
							user={this.state.user}
							onSignIn={(user) => this.handleUpdateUser(user)}
							onSignOut={() => this.handleUpdateUser(null)}
						>
							<Switch>
								<Route
									exact
									path={routes.auth.signIn}
									render={(routeProps) => (
										<SignIn {...routeProps} config={this.state.config!} user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />
									)}
								/>
								<Route exact path={routes.auth.register} render={() => <Register user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />} />
								<Route exact path={routes.auth.forgotPassword} render={(routeProps) => <ForgotPassword {...routeProps} user={this.state.user} />} />
								<Route exact path={routes.auth.forgotPasswordSuccess} render={() => <ForgotPasswordSuccess user={this.state.user} />} />
								<Route
									exact
									path={routes.auth.resetPassword}
									render={(routeProps) => <ResetPassword {...routeProps} user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>

								{this.state.user && this.state.user.roles.filter((r) => r === "MediaProducer").length > 0 ? (
									<PrivateRoute
										exact
										path={routes.home}
										user={this.state.user}
										render={() => <MediaDashboard user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />}
									/>
								) : (
									<PrivateRoute
										exact
										path={routes.home}
										user={this.state.user}
										render={() => (
											<AccountDashboard user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />
										)}
									/>
								)}

								<PrivateRoute
									exact
									path={routes.admin.impersonate}
									user={this.state.user}
									render={(routeProps) => <Impersonate {...routeProps} user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>
								<PrivateRoute exact path={routes.admin.notes} user={this.state.user} render={() => <Notes user={this.state.user!} />} />
								<PrivateRoute exact path={routes.admin.freeProAccess} user={this.state.user} render={() => <FreeProAccess user={this.state.user!} />} />

								<PrivateRoute exact path={routes.pro.myMembership} user={this.state.user} render={() => <MyProMembershipPage user={this.state.user!} config={this.state.config!} />} />

								<PrivateRoute
									exact
									path={routes.account.changeEmail}
									user={this.state.user}
									render={() => <ChangeEmail user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>
								<PrivateRoute exact path={routes.account.changePassword} user={this.state.user} render={() => <ChangePassword user={this.state.user!} />} />
								<PrivateRoute
									exact
									path={routes.account.changePhone}
									user={this.state.user}
									render={() => <ChangePhone user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>
								<PrivateRoute
									exact
									path={routes.account.paymentMethods}
									user={this.state.user}
									render={() => <PaymentMethods config={this.state.config!} user={this.state.user!} />}
								/>
								<RedirectRoute exact path={routes.account.billingStatements} redirectPath={routes.pro.myMembership} />

								<PrivateRoute exact path={routes.media.downloads} user={this.state.user} component={Downloads} />
								<PrivateRoute exact path={routes.media.purchases} user={this.state.user} component={Purchases} />
								<PrivateRoute exact path={routes.media.activations} user={this.state.user} component={Activations} />
								<PrivateRoute
									exact
									path={routes.media.manage}
									user={this.state.user}
									render={(routeProps) => (
										<ManageMedia {...routeProps} key={routeProps.location.pathname} config={this.state.config!} user={this.state.user!} />
									)}
								/>
								<PrivateRoute
									exact
									path={routes.media.pendingAdd}
									user={this.state.user}
									render={(routeProps) => <PendingVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />}
								/>
								<PrivateRoute
									exact
									path={routes.media.pendingEdit}
									user={this.state.user}
									render={(routeProps) => <PendingVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />}
								/>
								<PrivateRoute
									exact
									path={routes.media.approvedEdit}
									user={this.state.user}
									render={(routeProps) => <ApprovedVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />}
								/>

								<PrivateRoute exact path={routes.contributions.dashboard} user={this.state.user} component={ContributorDashboard} />
								<PrivateRoute exact path={routes.contributions.sermonIllustrations} user={this.state.user} component={SermonIllustrations} />
								<PrivateRoute exact path={routes.contributions.addSermonIllustration} user={this.state.user} component={EditSermonIllustration} />
								<PrivateRoute exact path={routes.contributions.editSermonIllustration} user={this.state.user} component={EditSermonIllustration} />
								<PrivateRoute exact path={routes.contributions.sermons} user={this.state.user} component={Sermons} />
								<PrivateRoute exact path={routes.contributions.addSermon} user={this.state.user} component={EditSermon} />
								<PrivateRoute exact path={routes.contributions.editSermon} user={this.state.user} component={EditSermon} />
								<PrivateRoute exact path={routes.contributions.sermonSeries} user={this.state.user} component={SermonSeries} />
								<PrivateRoute exact path={routes.contributions.addSeries} user={this.state.user} component={AddEditSeries} />
								<PrivateRoute exact path={routes.contributions.editSeries} user={this.state.user} component={AddEditSeries} />

								<PrivateRoute exact path={routes.discussion.notifications} user={this.state.user} component={DiscussionNotifications} />
								<PrivateRoute exact path={routes.messages.private} user={this.state.user} component={PrivateMessages} />
								<PrivateRoute
									exact
									path={routes.sermonFolders.base}
									user={this.state.user}
									render={(routeProps) => <SermonFolders {...routeProps} config={this.state.config!} />}
								/>
								<PrivateRoute
									exact
									path={routes.sermonFolders.id}
									user={this.state.user}
									render={(routeProps) => <SermonFolders {...routeProps} config={this.state.config!} />}
								/>
								<PrivateRoute
									exact
									path={routes.viewingHistory}
									user={this.state.user}
									render={(routeProps) => <ViewingHistory {...routeProps} config={this.state.config!} user={this.state.user!} />}
								/>

								<PrivateRoute exact path={routes.preferences.bibleTranslation} user={this.state.user} component={BibleTranslation} />
								<PrivateRoute
									exact
									path={routes.preferences.newsletterSubscriptions}
									user={this.state.user}
									render={() => <NewsletterSubscriptions user={this.state.user!} />}
								/>
								<PrivateRoute exact path={routes.preferences.notifications} user={this.state.user} component={Notifications} />

								<PrivateRoute
									exact
									path={routes.profile.aboutMe}
									user={this.state.user}
									render={() => <AboutMe user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>
								<PrivateRoute
									exact
									path={routes.profile.myChurch}
									user={this.state.user}
									render={() => <MyChurch user={this.state.user!} config={this.state.config!} />}
								/>
								<PrivateRoute exact path={routes.profile.myStory} user={this.state.user} render={() => <MyStory user={this.state.user!} />} />
								<PrivateRoute exact path={routes.profile.sharedLinks} user={this.state.user} render={() => <SharedLinks user={this.state.user!} />} />
								<PrivateRoute
									exact
									path={routes.profile.profileImage}
									user={this.state.user}
									render={() => <ProfileImage user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />}
								/>

								<Redirect to={routes.home} />
							</Switch>
						</Layout>
					</AnalyticsPageTracker>
				</BrowserRouter>
			);
		}
	}
}

export default App;
