import {
    Button,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AccountService } from "../../../services/AccountService";

interface Props {
  classes: any;
  sparkSiteUrl: string;
  hasSparkSubscription?: boolean;
}

export function SparkAccountCard(props: Props) {
  const { classes, sparkSiteUrl, hasSparkSubscription } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" component="h4">
            Sermon Research
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary={
                hasSparkSubscription === null
                  ? "..." : 
                  hasSparkSubscription
                    ? "Standard"
                    : "Free"
              }
              secondary="Subscription Level"
            />
            <ListItemSecondaryAction className={classes.secondaryAction}>
              <a href={`${sparkSiteUrl}/app/sparks`} className={classes.link}>
                <Button size="small" color="primary">Go to Dashboard</Button>
            </a>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
