import { ServerResponse, WebClient } from './ServiceHelper';
import { MediaItem, MediaType, MediaItemStatus } from '../entities/MediaItem';

export interface MediaItemHistory {
    id: number;
    imageUrl: string;
    title: string;
    type: string;
    summary: string;
    date: Date;
    shareableUrl: string;
    shareableUrlName: string;
    url: string;
    kitDownloadUrl: string;
    publiclyAvailable: boolean;
    shareLink: string;
    views: number;
    invoiceId?: number;
    invoiceNumber?: string;
}

export interface MediaItemInvoice {
    orderDate: Date;
    orderId: number;
    title: string;
    purchasePrice: number;
}

interface ServerMediaItem {
    id: number;
    title: string;
    summary: string;
    type: string;
    isEnabled: boolean;
    needsAttention: boolean;
    dateAvailable?: string;
    previewImageUrl: string;
    url: string;
    mediaProducerId: number;
    mediaProducer: string;
    scriptureReferences: string[];
    genres: string[];
    styles: string[];
    topics: string[];
    status: MediaItemStatus
}
export interface SearchMediaItemRequest {
    query: string;
    skip: number;
    take: number;
    sortBy: string;
    sortDescending: boolean;
    type: string;
    includeDisabled: boolean;
    needsAttention?: boolean;
    status: MediaItemStatus;
}

export interface MediaDisableRequest {
    title: string;
    id: number;
    reason: string;
}

export interface MediaDeleteRequest {
    title: string;
    id: number;
    reason: string;
}

export interface MediaEnableRequest {
    title: string;
    id: number;
    reason: string;
}

export interface MediaCancelRequest {
    id: number;
}
export class MediaService {

    public static async getDownloadHistory() {

        var response = await WebClient.Get<MediaItemHistory[]>("api/Media/GetDownloadHistory");
        if (ServerResponse.isServerResponse<MediaItemHistory[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getPurchaseHistory() {

        var response = await WebClient.Get<MediaItemHistory[]>("api/Media/GetPurchaseHistory");
        if (ServerResponse.isServerResponse<MediaItemHistory[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getActivationHistory() {

        var response = await WebClient.Get<MediaItemHistory[]>("api/Media/GetActivationHistory");
        if (ServerResponse.isServerResponse<MediaItemHistory[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getMediaInvoice(id: number) {

        var response = await WebClient.Get<MediaItemInvoice>("api/Media/GetMediaInvoice/" + id);
        if (ServerResponse.isServerResponse<MediaItemInvoice>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async GetInvoice(id: number) {

        var response = await WebClient.Get<MediaItemInvoice>("api/Media/GetInvoice/" + id);
        if (ServerResponse.isServerResponse<MediaItemInvoice>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async searchMediaItems(request: SearchMediaItemRequest) {
        let response = await WebClient.Post<ServerMediaItem[]>("api/Media/SearchMedia", request);
        if (ServerResponse.isSuccess(response)) {
            let translatedResponse: ServerResponse<MediaItem[]> = {
                message: response.message,
                data: response.data.map(r => {
                    let mediaItem: MediaItem = {
                        ...r,
                        type: MediaType[r.type],
                        dateAvailable: r.dateAvailable ? new Date(r.dateAvailable) : undefined
                    };
                    return mediaItem;
                })
            };
            return translatedResponse;
        }
        return response;
    }
    
    static async requestDisable(request: MediaDisableRequest) {
        return await WebClient.Post<ServerMediaItem[]>("api/Media/RequestDisable", request);   
    }

    static async requestDelete(request: MediaDeleteRequest) {
        return await WebClient.Post<ServerMediaItem[]>("api/Media/RequestDelete", request);   
    }

    static async requestEnable(request: MediaEnableRequest) {
        return await WebClient.Post<ServerMediaItem[]>("api/Media/RequestEnable", request);   
    }

    static async requestCancel(request: MediaCancelRequest) {
        return await WebClient.Post("api/Media/CancelPending", request);   
    }
}