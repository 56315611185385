import * as React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import ApplicationUser from "../entities/ApplicationUser";

type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>;

interface PrivateRouteProps extends RouteProps {
    user: ApplicationUser | null;
}

export const PrivateRoute: React.StatelessComponent<PrivateRouteProps> = ({ component, ...rest }) => {

    const renderComponent = (Component: RouteComponent) => (props: RouteComponentProps) => {

        return <Component {...props} />;
    };

    if (!rest.user) {
        return (
            <Route
                {...rest}
                render={(routeProps) => {

                    const redirectProps = {
                        to: {
                            pathname: "/auth/sign-in",
                            state: { from: routeProps.location },
                        },
                    };

                    return <Redirect {...redirectProps} />;
                }}
            />
        );
    }
    else if (component) {
        return <Route {...rest} render={renderComponent(component)} />;
    }
    else if (rest.render) {
        return <Route {...rest} render={rest.render} />;
    }
    else {
        return null;
    }
};