import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AppConfig from '../../entities/AppConfig';
import ApplicationUser from '../../entities/ApplicationUser';
import {
    ProSubscriptionSourceType,
    ProSubscriptionStatus,
    ProSubscriptionTerm,
    ProSubscriptionTier
} from '../../entities/ProSubscription';
import { AccountService, MediaStatistics, PlusMediaAccess, PremiumSermonSeriesKitAccess, SermonIllustrationStatistics, SermonStatistics } from '../../services/AccountService';
import { AdminService } from '../../services/AdminService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import { CurrencyHelper } from '../../utilities/CurrencyHelper';
import withRoot from '../../withRoot';
import { SparkAccountCard } from './components/SparkAccountCard';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        avatar: {
            margin: '0 auto',
            backgroundColor: theme.palette.primary.light,
            width: 100,
            height: 100,
            fontSize: '40px',
            marginBottom: theme.spacing(),
        },
        welcome: {
            textAlign: 'center',
            marginBottom: theme.spacing(),
        },
        title: {
            textAlign: 'center',
            marginBottom: theme.spacing() * 2,
        },
        card: {
            padding: theme.spacing(),
            width: 450,
            height: 550
        },
        cardShort: {
            padding: theme.spacing(),
            width: 450,
            height: 350
        },
        dangerButton: {
            color: theme.palette.error.dark
        },
        secondaryAction: {

        },
        link: {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('md')]: {
            card: {
                minWidth: '100%',
                width: 'auto',
                height: 'auto',
            }
        },
        [theme.breakpoints.down('sm')]: {
            secondaryAction: {
                position: 'static',
                transform: 'none',
                right: 'inherit',
                top: 'inherit',
                paddingLeft: theme.spacing() * 2
            }
        },
        [theme.breakpoints.down('xs')]: {
            secondaryAction: {
                paddingLeft: theme.spacing()
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    onSignIn: (user: ApplicationUser) => void;
    config: AppConfig;
    user: ApplicationUser;
}

type State = {
    enableSubmit: boolean;
    showSendResetPasswordEmailDialog: boolean;
    showResetPasswordEmailSentMessage: boolean;
    showCloseAccountDialog: boolean;
    user: ApplicationUser;
    mediaStatistics?: MediaStatistics;
    premiumSskAccess?: PremiumSermonSeriesKitAccess;
    plusMediaAccess?: PlusMediaAccess;
    sermonStatistics?: SermonStatistics;
    hasMakerSubscription?: boolean;
    hasSparkSubscription?: boolean;
    sermonIllustrationStatistics?: SermonIllustrationStatistics;
    errors: FieldValidationError[];
    hasPaypal: boolean;
};

class Home extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            showSendResetPasswordEmailDialog: false,
            showResetPasswordEmailSentMessage: false,
            showCloseAccountDialog: false,
            user: props.user,
            errors: [],
            hasPaypal: false
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Account Dashboard";


        const hasPayPal = await AccountService.hasPayPal();
        this.setState({ hasPaypal: hasPayPal || false });

        const mediaStatistics = await AccountService.getMediaStatistics();
        if (mediaStatistics) {
            this.setState({ mediaStatistics });
        }

        AccountService.getSubscriptions()
            .then(result => this.setState({ hasMakerSubscription: result?.hasMakerSubscription, hasSparkSubscription: result?.hasSparkSubscription }));

        if (this.state.user.proSubscription) {

            if (this.state.user.proSubscription.tier > ProSubscriptionTier.Plus) {
                const premiumSskAccess = await AccountService.getPremiumSermonSeriesKitAccess();
                if (premiumSskAccess) {
                    this.setState({ premiumSskAccess });
                }
            }

            if (this.state.user.proSubscription.tier > ProSubscriptionTier.Basic) {
                const plusMediaAccess = await AccountService.getPlusMediaAccess();
                if (plusMediaAccess) {
                    this.setState({ plusMediaAccess });
                }
            }
        }

        if (this.state.user.roles.filter((v) => v === 'Contributor').length > 0) {

            const sermonStatistics = await AccountService.getSermonStatistics();
            if (sermonStatistics) {
                this.setState({ sermonStatistics });
            }

            const sermonIllustrationStatistics = await AccountService.getSermonIllustrationStatistics();
            if (sermonIllustrationStatistics) {
                this.setState({ sermonIllustrationStatistics });
            }
        }
    }

    canChangeProSubscription(user: ApplicationUser) {

        return user.proSubscription && user.proSubscription.nextBillDate && user.proSubscription.status !== ProSubscriptionStatus.Cancelled;
    }

    canUpgradeProSubscription(user: ApplicationUser) {

        return this.canChangeProSubscription(user) && user.proSubscription!.tier < ProSubscriptionTier.Premium;
    }

    getPlanName(user: ApplicationUser) {
        if (user.proSubscription != null) {
            if (user.proSubscription.planName.match(/employee/i)) {
                return "Site Administrator";
            }
            if (user.proSubscription.planName.match(/basic/i)) {
                return "PRO Basic";
            }
            if (user.proSubscription.planName.match(/premium/i)) {
                if (user.proSubscription.source == ProSubscriptionSourceType.Gloo){
                    return "Gloo PRO Premium";
                }
                return "PRO Premium";
            }
            if (user.proSubscription.planName.match(/free pro/i)) {
                return "Free Contributor PRO";
            }

            return "PRO Plus";
        }

        return "Free Account";
    }

    getNextActivationDate(dateString: string) {

        const date = new Date(dateString);
        const current = new Date();

        if (current.getTime() > date.getTime()) {
            return 'Activate Today!';
        }
        else {
            return date.toLocaleDateString();
        }
    }

    async makeAdmin(userId: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.makeAdmin(userId);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                this.props.onSignIn(response.data);
                this.setState({ errors: [], user: response.data, enableSubmit: true });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    async unmakeAdmin(userId: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.unmakeAdmin(userId);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                this.props.onSignIn(response.data);
                this.setState({ errors: [], user: response.data, enableSubmit: true });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    async sendResetPasswordEmail(userId: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.sendResetPasswordEmail(userId);

            if (ServerResponse.isServerResponse<null>(response)) {

                this.setState({ errors: [], showSendResetPasswordEmailDialog: false, showResetPasswordEmailSentMessage: true, enableSubmit: true });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
        }
    }

    async closeAccount(userId: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.closeAccount(userId);

            if (ServerResponse.isServerResponse<null>(response)) {

                (window as any).location = '/admin/impersonate';
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], showSendResetPasswordEmailDialog: false, enableSubmit: true });
        }
    }

    renderMyAccount() {
        var classes = this.props.classes;
        var user = this.state.user;
        var proSubscription = this.state.user.proSubscription;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        My Account
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={this.getPlanName(user) + (proSubscription && proSubscription.inTrial ? ' (Trial)' : '')}
                                secondary="Current plan"
                            />
                            {!this.props.user.proSubscription &&
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                    <a href={`${this.props.config.paymentsSiteUrl}/subscribe`} className={classes.link}>
                                        <Button size="small" color="primary">Upgrade Plan</Button>
                                    </a>
                                </ListItemSecondaryAction>
                            }
                            {this.canUpgradeProSubscription(user) &&
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                    <a href={`${this.props.config.paymentsSiteUrl}/upgrade`} className={classes.link}>
                                        <Button size="small" color="primary">Upgrade Plan</Button>
                                    </a>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={new Date(user.dateCreated).toLocaleDateString()}
                                secondary="Member since"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                {!this.state.user.inAdminImpersonationMode &&
                                    <a href={`${this.props.config.mainSiteUrl}/contactus`} className={classes.link}>
                                        <Button size="small" className={classes.dangerButton}>Close Account</Button>
                                    </a>
                                }
                                {this.state.user.inAdminImpersonationMode &&
                                    <Button size="small" className={classes.dangerButton} onClick={() => this.setState({ showCloseAccountDialog: true })}>Close Account</Button>
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={user.email}
                                secondary="Email"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to='/account/change-email' className={classes.link}>
                                    <Button size="small" color="primary">Change Email</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={user.phoneNumber}
                                secondary="Phone"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to='/account/change-phone' className={classes.link}>
                                    <Button size="small" color="primary">Change Phone</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {user.isGlooLogin &&
                            <ListItem>
                                <ListItemText
                                    primary="Gloo"
                                    secondary="Account Source"
                                />
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                        <a href={this.props.config.glooUserProfileUrl} className={classes.link}>
                                            <Button size="small" color="primary">View Account</Button>
                                        </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                        }
                        {user.inAdminImpersonationMode &&
                            <ListItem>
                                <ListItemText
                                    primary={'*'.repeat(user.email.length)}
                                    secondary="Password"
                                />
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                    <Link to='/account/change-password' className={classes.link}>
                                        <Button size="small" color="primary">Change Password</Button>
                                    </Link>
                                </ListItemSecondaryAction>
                            </ListItem>
                        }
                        {user.inAdminImpersonationMode &&
                            <Dialog
                                open={this.state.showCloseAccountDialog}
                                onClose={() => this.setState({ showCloseAccountDialog: !this.state.showCloseAccountDialog })}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Close Account?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Closing a user's account only affects the user's ability to log into their account - this does not delete their contributions, and it does not close their Zuora account.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showCloseAccountDialog: !this.state.showCloseAccountDialog })}>
                                        No
                                    </Button>
                                    <Button disabled={!this.state.enableSubmit} onClick={() => this.closeAccount(user.id)} color="primary">
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>}
                    </List>
                </CardContent>
                {user.inAdminImpersonationMode &&
                    <React.Fragment>
                        <Divider />
                        <CardActions>
                            <Link to="/admin/free-pro-access" className={classes.link}>
                                <Button disabled={!this.state.enableSubmit} size="small">Give Free PRO Access</Button>
                            </Link>
                            {(!user.proSubscription || !user.proSubscription.planName.match(/employee/i)) &&
                                <Button disabled={!this.state.enableSubmit} size="small" onClick={(e) => this.makeAdmin(user.id)}>Make Admin</Button>
                            }
                            {(user.proSubscription && user.proSubscription.planName.match(/employee/i)) &&
                                <Button disabled={!this.state.enableSubmit} size="small" onClick={(e) => this.unmakeAdmin(user.id)}>Unmake Admin</Button>
                            }
                            <Button disabled={!this.state.enableSubmit} size="small" onClick={() => this.setState({ showSendResetPasswordEmailDialog: !this.state.showSendResetPasswordEmailDialog })}>Send Reset Password Email</Button>
                            <Dialog
                                open={this.state.showSendResetPasswordEmailDialog}
                                onClose={() => this.setState({ showSendResetPasswordEmailDialog: !this.state.showSendResetPasswordEmailDialog })}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Send Reset Password Email?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        The user will receive an email with a link to reset their own password, so that you don't have to set one for them.
                                            </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ showSendResetPasswordEmailDialog: !this.state.showSendResetPasswordEmailDialog })}>
                                        No
                                    </Button>
                                    <Button onClick={() => this.sendResetPasswordEmail(user.id)} color="primary">
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </CardActions>
                    </React.Fragment>
                }
            </Card>
        );
    }

    renderMyPRO() {
        var classes = this.props.classes;
        var user = this.state.user;
        var proSubscription = this.state.user.proSubscription;
        var currency = user.currency;

        if (!proSubscription) {
            return null;
        }

        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        My PRO
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={ProSubscriptionTier[proSubscription.tier] + (proSubscription.inTrial ? ' (Trial)' : '')}
                                secondary="Benefits level"
                            />
                            {this.canChangeProSubscription(user) &&
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                    <a href={`${this.props.config.mainSiteUrl}/pro-benefits`} className={classes.link}>
                                        <Button size="small" color="primary">View Benefits</Button>
                                    </a>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={ProSubscriptionStatus[proSubscription.status]}
                                secondary="Membership status"
                            />
                            {this.canChangeProSubscription(user) &&
                                <ListItemSecondaryAction className={classes.secondaryAction}>
                                    {this.state.hasPaypal ?
                                        <a target="_blank" rel="noopener noreferrer" href={`${this.props.config.paypalUrl}/myaccount/autopay/connect`} className={classes.link}>
                                            <Button size="small" className={classes.dangerButton}>Manage</Button>
                                        </a>
                                        :
                                        <a href={`${this.props.config.paymentsSiteUrl}/cancel`} className={classes.link}>
                                            <Button size="small" className={classes.dangerButton}>Cancel Membership</Button>
                                        </a>
                                    }
                                </ListItemSecondaryAction>
                            }
                            {proSubscription.serviceStopDate && proSubscription.status === ProSubscriptionStatus.Cancelled &&
                                <Typography color="secondary">Benefits end {new Date(proSubscription.serviceStopDate).toLocaleDateString()}</Typography>
                            }
                        </ListItem>
                        {proSubscription.status === ProSubscriptionStatus.Active &&
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <>
                                            {ProSubscriptionTerm[proSubscription.term].toString()}
                                            {proSubscription.nextBillPrice && proSubscription.planPrice ?
                                                <span>{proSubscription.nextBillPrice < proSubscription.planPrice ?
                                                    <span> (<strong>{CurrencyHelper.GetCurrencySymbol(currency)}{proSubscription.nextBillPrice.toString(10)}{proSubscription.term === 1 ? " / mth" : proSubscription.term === 12 ? " / yr" : " / 2 years"}</strong> | <del>{CurrencyHelper.GetCurrencySymbol(currency)}{proSubscription.planPrice.toString(10)}{proSubscription.term === 1 ? " / mth" : proSubscription.term === 12 ? " / yr" : " / 2 years"}</del>)</span>
                                                    : <span> ({CurrencyHelper.GetCurrencySymbol(currency)}{proSubscription.planPrice.toString(10)}{proSubscription.term === 1 ? " / month" : proSubscription.term === 12 ? " / year" : "/ 2 years"})</span>}</span>
                                                : ''
                                            }
                                            {this.getPlanName(user) === 'Free Contributor PRO' ? ' (Free Contributor PRO)' : ''}
                                            {this.getPlanName(user) === 'Site Administrator' ? ' (Site Administrator PRO)' : ''}
                                        </>
                                    }
                                    secondary="Payment option"
                                />
                                {this.canUpgradeProSubscription(user) &&
                                    <ListItemSecondaryAction className={classes.secondaryAction}>
                                        <a href={`${this.props.config.paymentsSiteUrl}/upgrade`} className={classes.link}>
                                            <Button size="small" color="primary">Upgrade Plan</Button>
                                        </a>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        }
                        {proSubscription.nextBillDate && proSubscription.status === ProSubscriptionStatus.Active &&
                            <ListItem>
                                <ListItemText
                                    primary={new Date(proSubscription.nextBillDate).toLocaleDateString()}
                                    secondary="Payment due"
                                />
                                {this.canChangeProSubscription(user) &&
                                    <ListItemSecondaryAction className={classes.secondaryAction}>
                                        <Link to='/account/payment-methods' className={classes.link}>
                                            <Button size="small" color="primary">Change Payment Method</Button>
                                        </Link>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        }
                    </List>
                </CardContent>
            </Card>
        );
    }

    renderSermonMakerStatus() {
        const classes = this.props.classes;
        const hasMakerSubscription = this.state.hasMakerSubscription;
        const showUpgradeButton = hasMakerSubscription != null && !hasMakerSubscription;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <img src="//i.cdn-sc.com/Logos/sm-horiz300.png" style={{ width: '35%', paddingBottom: 10, paddingTop: 10 }} alt="Sermon Maker" />
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={hasMakerSubscription == null ? '...' : hasMakerSubscription ? 'Standard' : 'Free'}
                                secondary="Benefits level"
                            />
                            {
                                showUpgradeButton
                                    ?
                                    <ListItemSecondaryAction className={classes.secondaryAction}>
                                        <a href={`${this.props.config.makerSiteUrl}/library-home?upgrade=standard`} className={classes.link}>
                                            <Button size="small" color="primary">Change Plan</Button>
                                        </a>
                                    </ListItemSecondaryAction>
                                    :
                                    <ListItemSecondaryAction className={classes.secondaryAction}>
                                        <a href={`${this.props.config.makerSiteUrl}/library-home`} className={classes.link}>
                                            <Button size="small" color="primary">Go To SermonMaker</Button>
                                        </a>
                                    </ListItemSecondaryAction>
                            }
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

    renderMyMedia() {
        var classes = this.props.classes;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        My Media
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={this.state.mediaStatistics ? this.state.mediaStatistics.totalDownloadCount.toLocaleString() : '...'}
                                secondary="Total media downloads"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.mediaStatistics ? this.state.mediaStatistics.totalPurchaseCount.toLocaleString() : '...'}
                                secondary="Total media purchases"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/purchases" className={classes.link}>
                                    <Button size="small" color="primary">View Purchases</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

    renderMyContributions() {
        var classes = this.props.classes;

        if (!this.state.user.roles.some(r => r === 'Contributor')) {
            return null;
        }

        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        My Contributions
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonStatistics ? this.state.sermonStatistics.approvedCount.toLocaleString() : '...'}
                                secondary="Approved sermons"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions/sermons?status=approved" className={classes.link}>
                                    <Button size="small" color="primary">View Approved</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonStatistics ? this.state.sermonStatistics.pendingCount.toLocaleString() : '...'}
                                secondary="Pending sermons"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions/sermons?status=pending" className={classes.link}>
                                    <Button size="small" color="primary">View Pending</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonStatistics ? this.state.sermonStatistics.totalViewCount.toLocaleString() : '...'}
                                secondary="Total sermon views"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions" className={classes.link}>
                                    <Button size="small" color="primary">View Dashboard</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonIllustrationStatistics ? this.state.sermonIllustrationStatistics.approvedCount.toLocaleString() : '...'}
                                secondary="Approved sermon illustrations"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions/sermon-illustrations?status=approved" className={classes.link}>
                                    <Button size="small" color="primary">View Approved</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonIllustrationStatistics ? this.state.sermonIllustrationStatistics.pendingCount.toLocaleString() : '...'}
                                secondary="Pending sermon illustrations"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions/sermon-illustrations?status=pending" className={classes.link}>
                                    <Button size="small" color="primary">View Pending</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.sermonIllustrationStatistics ? this.state.sermonIllustrationStatistics.totalViewCount.toLocaleString() : '...'}
                                secondary="Total sermon illustration views"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/contributions" className={classes.link}>
                                    <Button size="small" color="primary">View Dashboard</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

    renderPremiumSermonSeriesKitAccess() {

        var classes = this.props.classes;
        var proSubscription = this.state.user.proSubscription;

        if (!proSubscription || proSubscription.tier <= ProSubscriptionTier.Plus) {
            return null;
        }

        return (
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        Premium Sermon Series Kit Access
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={this.state.premiumSskAccess ? (this.state.premiumSskAccess.lastActivatedName ? this.state.premiumSskAccess.lastActivatedName : 'None') : '...'}
                                secondary="Activated SSK"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <a href={this.state.premiumSskAccess && this.state.premiumSskAccess.lastActivatedUrl ? this.state.premiumSskAccess.lastActivatedUrl : '#'} className={classes.link}>
                                    <Button size="small" color="primary">Download SSK</Button>
                                </a>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.premiumSskAccess ? (this.state.premiumSskAccess.nextActivationDate ? this.getNextActivationDate(this.state.premiumSskAccess.nextActivationDate) : 'None') : '...'}
                                secondary="Next SSK activation date"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <a href={`${this.props.config.mainSiteUrl}/sermon-series-kits`} className={classes.link}>
                                    <Button size="small" color="primary">View All SSKs</Button>
                                </a>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.premiumSskAccess ? (this.state.premiumSskAccess.activationCount.toLocaleString()) : '...'}
                                secondary="Activation count"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/activations" className={classes.link}>
                                    <Button size="small" color="primary">View Activations</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

    renderPROMediaDownloads() {

        var classes = this.props.classes;
        var proSubscription = this.state.user.proSubscription;

        if (!proSubscription || proSubscription.tier <= ProSubscriptionTier.Basic) {
            return null;
        }

        return (
            <Card className={classes.card} style={{overflow: 'auto'}}>
                <CardContent>
                    <Typography variant="h6" component="h4">
                        PRO Media Downloads
                    </Typography>
                    {this.state.plusMediaAccess && this.state.plusMediaAccess.periodStart && this.state.plusMediaAccess.periodEnd &&
                        <Typography variant="body2">
                            Between {new Date(this.state.plusMediaAccess.periodStart).toLocaleDateString()} and {new Date(this.state.plusMediaAccess.periodEnd).toLocaleDateString()}
                        </Typography>
                    }
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualBackgroundImageDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedBackgroundImageDownloads ? this.state.plusMediaAccess.allowedBackgroundImageDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Still Backgrounds"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=stillbackgrounds" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualCountdownVideoDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedCountdownVideoDownloads ? this.state.plusMediaAccess.allowedCountdownVideoDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Countdown videos"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=countdownvideos" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualMotionBackgroundDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedMotionBackgroundDownloads ? this.state.plusMediaAccess.allowedMotionBackgroundDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Motion backgrounds"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=motionbackgrounds" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualPreachingSlidesDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedPreachingSlidesDownloads ? this.state.plusMediaAccess.allowedPreachingSlidesDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Preaching Slides"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=preachingslides" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualWorshipLyricSlideDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedWorshipLyricSlideDownloads ? this.state.plusMediaAccess.allowedWorshipLyricSlideDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Worship Lyric Slides"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=worshiplyricslides" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualVideoIllustrationDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedVideoIllustrationDownloads ? this.state.plusMediaAccess.allowedVideoIllustrationDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Video illustrations"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=videoillustrations" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualComedyVideoDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedComedyVideoDownloads ? this.state.plusMediaAccess.allowedComedyVideoDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Comedy Videos"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=comedy" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualInspirationalVideoDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedInspirationalVideoDownloads ? this.state.plusMediaAccess.allowedInspirationalVideoDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Inspirational Videos"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=inspirational" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualSmallGroupStudyVideoDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedSmallGroupStudyVideoDownloads ? this.state.plusMediaAccess.allowedSmallGroupStudyVideoDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Small Group Study Videos"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=smallgroupstudy" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={this.state.plusMediaAccess ?
                                    (this.state.plusMediaAccess.actualSocialGraphicDownloads
                                        + ' / '
                                        + (this.state.plusMediaAccess.allowedSocialGraphicDownloads ? this.state.plusMediaAccess.allowedSocialGraphicDownloads.toLocaleString() : 'Unlimited')) :
                                    '...'}
                                secondary="Social Graphics"
                            />
                            <ListItemSecondaryAction className={classes.secondaryAction}>
                                <Link to="/media/downloads?type=socialgraphics" className={classes.link}>
                                    <Button size="small" color="primary">View Downloads</Button>
                                </Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.showResetPasswordEmailSentMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showResetPasswordEmailSentMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Reset password email sent</span>
                            </span>}
                    />
                </Snackbar>

                {this.props.user.profileImageUrl ?
                    <Avatar className={classes.avatar} src={this.props.user.profileImageUrl} /> :
                    <Avatar className={classes.avatar}>{this.props.user.firstName.charAt(0)}{this.props.user.lastName.charAt(0)}</Avatar>
                }

                <Typography className={classes.welcome} variant="subtitle1" component="h3">
                    Welcome back, {this.props.user.firstName}!
                </Typography>

                <Typography className={classes.title} variant="h5" component="h2">
                    Control your account, access your PRO benefits, and manage your contributions
                </Typography>

                <Hidden mdDown>
                    <Grid container justify="center" spacing={4}>
                        <Grid item>
                            {this.renderMyAccount()}
                        </Grid>
                        {this.props.user.proSubscription &&
                            <Grid item>
                                {this.renderMyPRO()}
                            </Grid>
                        }
                        <Grid item>
                            {this.renderMyMedia()}
                        </Grid>
                        <Grid item>
                            {this.renderSermonMakerStatus()}
                        </Grid>
                        <Grid item>
                            <SparkAccountCard classes={this.props.classes} sparkSiteUrl={this.props.config.sparkSiteUrl} hasSparkSubscription={this.state.hasSparkSubscription} />
                        </Grid>
                        {this.renderMyContributions() &&
                            <Grid item>
                                {this.renderMyContributions()}
                            </Grid>
                        }
                        {this.renderPremiumSermonSeriesKitAccess() &&
                            <Grid item>
                                {this.renderPremiumSermonSeriesKitAccess()}
                            </Grid>
                        }
                        {this.renderPROMediaDownloads() &&
                            <Grid item>
                                {this.renderPROMediaDownloads()}
                            </Grid>
                        }
                    </Grid>
                </Hidden>

                <Hidden lgUp>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            {this.renderMyAccount()}
                        </Grid>
                        {this.props.user.proSubscription &&
                            <Grid item xs={12}>
                                {this.renderMyPRO()}
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {this.renderMyMedia()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderSermonMakerStatus()}
                        </Grid>
                        <Grid item xs={12}>
                            <SparkAccountCard classes={this.props.classes} sparkSiteUrl={this.props.config.sparkSiteUrl} />
                        </Grid>
                        {this.renderMyContributions() &&
                            <Grid item>
                                {this.renderMyContributions()}
                            </Grid>
                        }
                        {this.renderPremiumSermonSeriesKitAccess() &&
                            <Grid item>
                                {this.renderPremiumSermonSeriesKitAccess()}
                            </Grid>
                        }
                        {this.renderPROMediaDownloads() &&
                            <Grid item>
                                {this.renderPROMediaDownloads()}
                            </Grid>
                        }
                    </Grid>
                </Hidden>

            </div>
        );
    }
}

export default withRoot(withStyles(styles)(Home));
